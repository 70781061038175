import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import keyItemsDictionary, { KeyItemId } from '../components/chartkey/keyItems';
import Tabs, { TabData } from './../components/tabs/Tabs';
import Screen from '../components/screen/Screen.component';
import Section from '../components/section/Section.component';
import { AppState } from './../reducer';
import { Spacer } from '../styles/components/ScreenStyles';
import ChartKey, {
  ChartKeyType
} from '../components/chartkey/ChartKey.component';
import { LoadingStatus } from './../utils/LoadingData';
import loadBehaviourSummary from './../state/entities/summary/loadBehaviourSummary';
import BehaviourItemsDisplay from '../components/behaviourchart/BehaviourItemsDisplay';
import useAuditId from './../hooks/useAuditId';
import { AuditType, auditTypeMap } from '../components/types/AuditType';
import getJobFunctionOptions, {
  JobFunctionOptions
} from './../hooks/useJobFunctionOptions';
import JobFunction from './../state/entities/jobFunctionAliases/JobFunction';

type SummaryItem = {
  subject: string;
  score: number;
  isCommercial: boolean;
};

const BehaviourScreen = (props: any) => {
  const dispatch = useDispatch();

  const token = useSelector((state: AppState) => state.auditToken.data.token);

  const summary = useSelector((state: AppState) => state.summary);

  const regions = useSelector((state: AppState) => state.regions.value);

  const reportData = useSelector((state: AppState) => state.report.data);

  const [jfOptions, setJfOptions] = useState<any[]>([]);

  const [summaryData, setSummaryData] = useState<SummaryItem[]>([]);

  const [isCommercial, setIsCommercial] = useState(true);

  const [useFilter, setUseFilter] = useState(false);

  const { behaviour } = reportData.data;

  const { auditId } = useAuditId();

  const jobFunctions = useSelector(
    (state: AppState) => state.jobFunctions.data
  ) as JobFunction[];

  const [jobFunctionOptions, setJobFunctionOptions] = useState<
    JobFunctionOptions | undefined
  >();

  function onTabChanged(tabData: TabData) {
    setIsCommercial(tabData.id === 'commercial');
  }

  useEffect(() => {
    if (jobFunctions) {
      setJobFunctionOptions(getJobFunctionOptions(jobFunctions));
    }
  }, [jobFunctions]);

  useEffect(() => {
    if (summary.status === LoadingStatus.INITIAL) {
      dispatch(loadBehaviourSummary(token, auditId));
    }
  }, [summary.status, dispatch, token, auditId]);

  useEffect(() => {
    // get summary from API
    // summary contains all data commercial & non commercial
    // if only one exists, no need for toggle
    if (summary.isLoaded) {
      const summaryItems: SummaryItem[] = summary.value;
      const filteredItems = summaryItems.filter(
        (item) => item.isCommercial === isCommercial
      );

      // use commercial if commercial items are present
      const useCommercial = !!summaryItems.find(
        (item) => item.isCommercial === true
      );

      // use nonCommercial if nonCommercial items are present
      const useNonCommercial = !!summaryItems.find(
        (item) => item.isCommercial === false
      );

      // use filter if both types are present
      setUseFilter(useCommercial && useNonCommercial);

      // use filtered items is filter is needed:
      setSummaryData(
        useCommercial && useNonCommercial ? filteredItems : summaryItems
      );
    }
  }, [summary, isCommercial]);

  useEffect(() => {
    let options = jobFunctionOptions?.all;
    if (useFilter) {
      options = isCommercial
        ? jobFunctionOptions?.commercial
        : jobFunctionOptions?.nonCommercial;
    }
    setJfOptions(options || []);
  }, [isCommercial, jobFunctionOptions, useFilter]);

  const report = useSelector((state: AppState) => state.report);

  const auditType = auditTypeMap.get(report.value.audit.auditType) as AuditType;

  const keyItems = [keyItemsDictionary[KeyItemId.PRE_PROGRAMME]];
  if (auditType === AuditType.POST) {
    keyItems.push(keyItemsDictionary[KeyItemId.POST_PROGRAMME]);
  }

  return (
    <Screen
      heading="Behavior"
      intro="This is an in-depth analysis of your organization's behaviour."
    >
      {/* Results Section */}
      <Section icon="globe_user" heading="Behavior" id="behavior">
        <p>{behaviour.results.intro}</p>

        <Spacer height={30} />

        {useFilter && (
          <>
            <TabsContainer>
              <Tabs
                selectedTabId={'commercial'}
                tabData={[
                  { id: 'commercial', label: 'Commercial' },
                  { id: 'non-commercial', label: 'Non Commercial' }
                ]}
                onChange={onTabChanged}
              />
            </TabsContainer>

            <Spacer height={20} />
          </>
        )}

        <ChartKey items={keyItems} type={ChartKeyType.DETAILED} />

        <Spacer height={20} />

        <BehaviourItemsDisplay
          data={summaryData}
          jobFunctionOptions={jfOptions}
          regionOptions={regions}
        />

        <Spacer height={20} />
      </Section>
    </Screen>
  );
};

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default BehaviourScreen;
