import { v4 as uuidv4 } from 'uuid';

export enum LoadingStatus {
  INITIAL = 'INITIAL',
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export type LoadingData = {
  status: LoadingStatus;
  isLoading: boolean;
  isLoaded: boolean;
  isError: boolean;
  data: any | null;
  value: any | null;
  errorMessage: string | undefined;
};

export const noTransform = (data: any) => data;

export function getLoadingData(
  status: LoadingStatus,
  { data, errorMessage, value }: LoadingDataPayload = {}
): LoadingData {
  return {
    status,
    isLoading: status === LoadingStatus.REQUEST,
    isLoaded: status === LoadingStatus.SUCCESS,
    isError: status === LoadingStatus.FAILURE,
    data: status === LoadingStatus.SUCCESS ? data : null,
    value: status === LoadingStatus.SUCCESS ? value : null,
    errorMessage: status === LoadingStatus.FAILURE ? errorMessage : undefined
  };
}

export type LoadingDataPayload = {
  data?: any;
  value?: any;
  errorMessage?: string;
};

export function resetLoadingData(dispatch: Function) {
  return (type: string, key?: string) => {
    const resetAction = setLoadingData(
      type,
      getLoadingData(LoadingStatus.INITIAL),
      key
    );
    dispatch(resetAction);
  };
}

export function loadingDataRequest(dispatch: Function) {
  return async (
    endpoint: string,
    options: object,
    config: LoadingDataConfig,
    key?: string
  ) => {
    const requestAction = setLoadingData(
      config.type,
      getLoadingData(LoadingStatus.REQUEST),
      key
    );
    dispatch(requestAction);
    try {
      const response = await fetch(endpoint, options);
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseData = await response.json();
      const successAction = setLoadingData(
        config.type,
        getLoadingData(LoadingStatus.SUCCESS, {
          data: responseData,
          value: config.transform(responseData)
        }),
        key
      );
      dispatch(successAction);
      return;
    } catch (error) {
      const failureAction = setLoadingData(
        config.type,
        getLoadingData(LoadingStatus.FAILURE, {
          errorMessage: (error as Error).message
        }),
        key
      );
      dispatch(failureAction);
      return;
    }
  };
}

export function setLoadingData(
  type: string,
  payload: LoadingData,
  key?: string
) {
  return {
    type,
    payload,
    key
  };
}

export const initialData = getLoadingData(LoadingStatus.INITIAL);

export function getReducer(actionName: string) {
  return (
    state: LoadingData = initialData,
    { type, payload }: { type: string; payload: LoadingData }
  ) => {
    return type === actionName ? payload : state;
  };
}

export type KeyReducerState = {
  [key: string]: LoadingData;
};

export function getKeyReducer(actionName: string) {
  return (
    state: KeyReducerState = {},
    { type, payload, key }: { type: string; payload: LoadingData; key: string }
  ) => {
    if (type !== actionName) {
      return state;
    }
    const newState = { ...state };
    newState[key] = payload;
    return newState;
  };
}

export type LoadingDataConfig = {
  type: string;
  reducer: any;
  transform: Function;
};

export function getConfig(options?: {
  type?: string;
  transform?: Function;
  reducer?: Function;
}): LoadingDataConfig {
  const actionName = options?.type || 'SET_LOADING_DATA_' + uuidv4();
  return {
    type: actionName,
    transform: options?.transform || noTransform,
    reducer: options?.reducer || getReducer(actionName)
  };
}
