import config from '../../../config';
import regions from './regions';
import { loadingDataRequest } from '../../../utils/LoadingData';
import {
  getOptions,
  getAuthHeader,
  HTTPMethod
} from '../../../utils/httpHelpers';

export const loadRegionsData = (token: string, auditId: string) => {
  return async (dispatch: Function, getState: Function) => {
    const endpoint = `${config.API_URL}/regions/${auditId}`;
    const options = getOptions(HTTPMethod.GET, getAuthHeader(token));
    await loadingDataRequest(dispatch)(endpoint, options, regions);
  };
};

export default loadRegionsData;
