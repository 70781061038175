import React from 'react';
// import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from '../reducer';
import Screen from '../components/screen/Screen.component';
import Section from '../components/section/Section.component';
import Comment from '../components/comment/Comment';
import { Spacer } from '../styles/components/ScreenStyles';

const CommentsScreen = (props: any) => {
  // loaded report data - contains copy but no stats:
  const reportData = useSelector((state: AppState) => state.report.data);

  // skillset copy (no stats)
  const { comments } = reportData.data;

  return (
    <Screen heading="Learner Comments" intro="">
      <Section
        icon="speech_bubble"
        heading="Learner Comments - Examples of Application"
        id="comments"
      >
        <Spacer height={20} />
        {comments.map((data: any, i: number) => (
          <Comment
            key={uuidv4()}
            quote={data.quote}
            role={data.role}
            isLeft={i % 2 === 0}
          />
        ))}
      </Section>
    </Screen>
  );
};

export default CommentsScreen;
