import { combineReducers } from 'redux';
import report from './state/entities/report/report';
import auditToken from './state/entities/auditToken/auditToken';
import { LoadingData, KeyReducerState } from './utils/LoadingData';
import results from './state/entities/results/results';
import summary from './state/entities/summary/summary';
import skillset from './state/entities/results/skillset';
import mindset from './state/entities/results/mindset';
import behaviour from './state/entities/results/behaviour';
import regions from './state/entities/regions/regions';
import jobFunctionAliases from './state/entities/jobFunctionAliases/jobFunctionAliases';

const rootReducer = combineReducers({
  report: report.reducer,
  results: results.reducer,
  skillset: skillset.reducer,
  mindset: mindset.reducer,
  behaviour: behaviour.reducer,
  auditToken: auditToken.reducer,
  summary: summary.reducer,
  regions: regions.reducer,
  jobFunctions: jobFunctionAliases.reducer
});

export type AppState = {
  report: LoadingData;
  regions: LoadingData;
  auditToken: LoadingData;
  results: LoadingData;
  summary: LoadingData;
  skillset: LoadingData;
  mindset: LoadingData;
  behaviour: KeyReducerState;
  jobFunctions: LoadingData;
};

export default rootReducer;
