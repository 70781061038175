import React, { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from './../reducer';
import { useScrollSection } from '../hooks/useScrollSection';
import Screen from '../components/screen/Screen.component';
import Section from '../components/section/Section.component';
import BulletHeading from './../components/bulletheading/BulletHeading.component';
import { useSelectedSection } from '../hooks/useSelectedSection';

const ExecutiveSummaryScreen = (props: any) => {
  const reportData = useSelector((state: AppState) => state.report.data);

  const { executiveSummary } = reportData.data;

  const sections = {
    introduction: useRef<HTMLDivElement>(null),
    methodology: useRef<HTMLDivElement>(null),
    businessObjectives: useRef<HTMLDivElement>(null),
    learningObjectives: useRef<HTMLDivElement>(null)
  };

  const { setSelectedSection } = useSelectedSection();

  useScrollSection(sections);

  useLayoutEffect(() => {
    setTimeout(() => {
      setSelectedSection('introduction');
    }, 10);
  }, [setSelectedSection]);

  return (
    <Screen
      heading="Executive Summary"
      intro="Here's a summary of the audit and its findings."
    >
      {/* Introduction Section */}
      <Section
        icon="mind_map"
        heading="Introduction & Top Line Summary"
        id="introduction"
        refObject={sections['introduction']}
      >
        {executiveSummary.introduction.map((paragraph: string) => (
          <p key={uuidv4()} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      </Section>

      {/* Methodology Section */}
      <Section
        icon="cog"
        heading="Methodology"
        id="methodology"
        refObject={sections['methodology']}
      >
        {executiveSummary.methodology.map((paragraph: string) => (
          <p key={uuidv4()} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}
      </Section>

      {/* Business Objectives Section */}
      <Section
        icon="arrow_target"
        heading="Business Objectives"
        id="businessObjectives"
        refObject={sections['businessObjectives']}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: executiveSummary.businessObjectives.intro
          }}
        />

        {executiveSummary.businessObjectives.objectives.map(
          (item: { bold: string; description: string }) => (
            <BulletHeading key={uuidv4()} bold={item.bold}>
              {item.description}
            </BulletHeading>
          )
        )}

        <p>{executiveSummary.businessObjectives.summary}</p>
      </Section>

      {/* Learning Outcomes Section */}
      <Section
        icon="mortarboard_hat"
        heading="Learning Objectives"
        id="learningObjectives"
        refObject={sections['learningObjectives']}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: executiveSummary.learningObjectives.intro
          }}
        />

        {executiveSummary.learningObjectives.objectives.map(
          (item: { bold: string; description: string }) => (
            <BulletHeading key={uuidv4()} bold={item.bold}>
              {item.description}
            </BulletHeading>
          )
        )}
      </Section>
    </Screen>
  );
};

export default ExecutiveSummaryScreen;
