import React from 'react';
import styled from 'styled-components';
import { Route } from 'react-router-dom';

import colors from '../styles/colors';
import { ScrollInfoProvider } from '../hooks/useScrollInfo';
import { SelectedSectionProvider } from '../hooks/useSelectedSection';
import useScrollToTop from './../hooks/useScrollToTop';
import OverviewScreen from './../screens/OverviewScreen';
import SkillsetScreen from './SkillsetScreen';
import MindsetScreen from './../screens/MindsetScreen';
import BehaviourScreen from './../screens/BehaviourScreen';
import ExecutiveSummaryScreen from './../screens/ExecutiveSummaryScreen';
import AnalysisScreen from './../screens/AnalysisScreen';
import CommentsScreen from './../screens/CommentsScreen';

// import SummaryScreen from './../screens/SummaryScreen';
import SideMenu from './../components/sidemenu/SideMenu.component';

const AuditReportScreen = (props: any) => {
  useScrollToTop();

  return (
    <Container>
      <ScrollInfoProvider>
        <SelectedSectionProvider>
          <SideMenuContainer>
            <SideMenu />
          </SideMenuContainer>
          <ScreenContainer>
            <Route
              path="/executive-summary"
              component={ExecutiveSummaryScreen}
            />
            <Route path="/results/overview" component={OverviewScreen} />
            <Route path="/results/skillset" component={SkillsetScreen} />
            <Route path="/results/mindset" component={MindsetScreen} />
            <Route path="/results/behavior" component={BehaviourScreen} />
            <Route path="/analysis" component={AnalysisScreen} />
            <Route path="/comments" component={CommentsScreen} />
          </ScreenContainer>
        </SelectedSectionProvider>
      </ScrollInfoProvider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const SideMenuContainer = styled.div`
  margin: 0;
  min-height: 100%;
  min-width: 320px;
  background: ${colors.black};
`;
const ScreenContainer = styled.div`
  flex: 1;
  margin: 20px 40px;
  // margin-left: 40px;
  padding-bottom: 300px;
  display: flex;
  justify-content: center;
`;

export default AuditReportScreen;
