import config from '../../../config';
// import results from './results';
import skillset from './skillset';
import mindset from './mindset';
import behaviour from './behaviour';

import { loadingDataRequest } from '../../../utils/LoadingData';
import {
  getOptions,
  getAuthHeader,
  HTTPMethod
} from '../../../utils/httpHelpers';

import { FilterData } from './FilterData';
import { getEndpoint } from './getEndpoint';
import ResultsType from './../../types/ResultsType';

export const loadResultsData = (token: string, filterData: FilterData) => {
  return async (dispatch: Function, getState: Function) => {
    if (!filterData.auditId || !filterData.resultsType) {
      throw new Error('invalid API request options');
    }
    const endpoint = getEndpoint(`${config.API_URL}/results/`, filterData);
    const options = getOptions(HTTPMethod.GET, getAuthHeader(token));
    await loadingDataRequest(dispatch)(
      endpoint,
      options,
      getResultsConfig(filterData.resultsType),
      filterData.subject
    );
  };
};

function getResultsConfig(type: ResultsType) {
  switch (type) {
    case ResultsType.MINDSET:
      return mindset;
    case ResultsType.BEHAVIOUR:
      return behaviour;
  }
  return skillset;
}

export default loadResultsData;
