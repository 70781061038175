import React from 'react';
import styled from 'styled-components';

import IcomoonReact from 'icomoon-react';

import iconSet from './../../assets/fonts/selection.json';
import colors from './../../styles/colors';
import ScoreCircle from '../scorecircle/ScoreCircle';

type HeaderBarProps = {
  title: string;
  score: number;
  isExpanded: boolean;
};

const HeaderBar = ({ title, score, isExpanded }: HeaderBarProps) => {
  return (
    <Container>
      <IcomoonReact
        iconSet={iconSet}
        color={colors.black}
        size={32}
        icon={isExpanded ? 'minus' : 'plus'}
      />

      <Title>{title}</Title>
      <Score>
        <ScoreLabel>Average Score:</ScoreLabel> <ScoreCircle score={score} />
      </Score>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const Title = styled.h3`
  flex: 1;
  padding-left: 20px;
`;

const Score = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ScoreLabel = styled.span`
  margin-right: 10px;
  font-family: 'DMSans Regular';
  color: ${colors.darkGreyText};
  font-size: 16px;
`;

export default HeaderBar;
