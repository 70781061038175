import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useSelectedSection } from './../../hooks/useSelectedSection';
// import Icon from '../icon/Icon.component';
import { LinkData } from './linkData.const';
import SubNav from './SubNav.component';
import colors from '../../styles/colors';

const NavLinkGroup = ({ linkData }: LinkData) => {
  const { heading, subnav } = linkData;

  const { selectedSection } = useSelectedSection();

  return (
    <Container>
      <Heading selected={heading.selected}>
        <LinkContainer>
          <LinkHeading selected={heading.selected} to={heading.to}>
            {heading.label}
          </LinkHeading>
        </LinkContainer>
      </Heading>
      {heading.selected && subnav?.length > 0 && (
        <SubNavContainer>
          <SubNav items={subnav} focusedItem={selectedSection} />
        </SubNavContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 40px;
  margin: 10px 0 0 20px;
`;

const Heading = styled.div`
  display: flex;
  height: 40px;
  padding-right: 20px;

  ::before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background: ${colors.pink};
    position: absolute;
    top: 13px;
    left: 15px;
    visibility: ${(p: { selected: boolean }) =>
      p.selected ? 'visible' : 'hidden'};
  }
`;

const LinkContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const LinkHeading = styled(Link)`
  text-decoration: none;
  font-family: ${(p: { selected: boolean }) =>
    p.selected ? 'Circular Bold' : 'Circular Medium'};
  color: ${(p: { selected: boolean }) =>
    p.selected ? 'white' : colors.greyMenuText};
  font-size: 20px;
  line-height: 1.3rem;
`;

const SubNavContainer = styled.ul`
  margin: 10px 0 0 20px;
  padding: 0;
`;

export default NavLinkGroup;
