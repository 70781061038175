import config from '../../../config';
import auditToken from './auditToken';
import { loadingDataRequest } from '../../../utils/LoadingData';
import {
  getOptions,
  getAuthHeader,
  HTTPMethod
} from '../../../utils/httpHelpers';

const loadAuditToken = (csAuthToken: string) => {
  return async (dispatch: Function, getState: Function) => {
    const endpoint = `${config.API_URL}/auth/token`;
    const options = getOptions(HTTPMethod.GET, getAuthHeader(csAuthToken));
    await loadingDataRequest(dispatch)(endpoint, options, auditToken);
  };
};

export default loadAuditToken;
