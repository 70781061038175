import React from 'react';
import styled from 'styled-components';

import AuditLink from './AuditLink';
import SubNavLink from './SubNavLink';

const SubNav = ({
  items,
  focusedItem
}: {
  focusedItem: string | undefined;
  items: AuditLink[];
}) => {
  const isSelected = (item: AuditLink, focusedItem: string | undefined) => {
    // return focusedItem ? item.to.includes(focusedItem) : item.selected;
    return item.to.includes(focusedItem || 'undefined');
  };

  return (
    <Container>
      {items.map((item) => (
        <SubNavItem key={item.label}>
          <SubNavLink
            selected={isSelected(item, focusedItem)}
            smooth
            to={item.to}
          >
            {item.label}
          </SubNavLink>
        </SubNavItem>
      ))}
    </Container>
  );
};

const Container = styled.ul`
  margin: 0;
  padding: 0 20px 0 0;
`;

const SubNavItem = styled.li`
  list-style-type: none;
  margin: 12px 0;
  padding: 0;
  :first-child {
    margin-top: 2px;
  }
`;

export default SubNav;
