import React from 'react';
import styled from 'styled-components';

import colors from './../../styles/colors';
import ScoreBar, { MarkerType } from './../barchart/ScoreBar.component';
import UpliftBar from './../barchart/UpliftBar.component';
import BackgroundBar from './../barchart/BackgroundBar';
import { AuditType } from '../types/AuditType';

type MindsetChartProps = {
  type: AuditType;
  title: string;
  description: string;
  target: number;
  pre: number;
  post?: number;
};

function transformMindsetStatement(statement: string) {
  return statement.split(':')[0];
}

const MindsetChart = ({
  type,
  description,
  title,
  target,
  pre,
  post
}: MindsetChartProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{transformMindsetStatement(description)}</Description>
      {type === AuditType.POST ? (
        <PostAuditChart pre={pre} post={post || 5} />
      ) : (
        <PreAuditChart score={pre} target={target} />
      )}
    </Container>
  );
};

type PreAuditChartProps = {
  score: number;
  target: number;
};

const PreAuditChart = ({ score, target }: PreAuditChartProps) => {
  return (
    <BarsContainer>
      <BackgroundBar />
      <UpliftBar arrows color={colors.pink} value={target} top={1} scale={5} />
      <ScoreBar
        thickness={6}
        color={colors.pink}
        value={target}
        type="none"
        markerType={MarkerType.UP}
        label={target.toString()}
        scale={5}
        top={-2}
      />
      <ScoreBar
        thickness={10}
        color={colors.sky}
        value={score}
        markerType={MarkerType.SHORT}
        label={score.toString()}
        scale={5}
      />
    </BarsContainer>
  );
};

type PostAuditChartProps = {
  pre: number;
  post: number;
};

const PostAuditChart = ({ pre, post }: PostAuditChartProps) => {
  return (
    <Container>
      <BarsContainer>
        <UpliftBar value={post} top={1} scale={100} arrows={true} />
        <ScoreBar
          thickness={10}
          color={colors.sky}
          value={pre}
          markerType={MarkerType.UP}
          label={pre.toString()}
          scale={5}
        />
        <ScoreBar
          thickness={10}
          color={colors.blue}
          value={post}
          markerType={MarkerType.SHORT}
          label={post.toString()}
          top={14}
          scale={5}
        />
      </BarsContainer>
    </Container>
  );
};

const Container = styled.div``;

const Title = styled.h2`
  font-family: 'Circular Bold';
  font-size: 16px;
  margin: 0;
`;

const Description = styled.p`
  font-family: 'Circular Book';
  font-size: 16px;
  margin: 0;
  color: ${colors.midGrey};
`;

const BarsContainer = styled.div`
  position: relative;
  height: 70px;
  margin-top: 40px;
`;

export default MindsetChart;
