import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import colors from '../../../styles/colors';
import ResultsSubNav from './ResultsSubNav';
import { LinkData } from './../linkData.const';

export function getSegments(pathname: string) {
  return pathname.replace(/^\//, '').split('/');
}

const ResultsNavGroup = ({ linkData }: LinkData) => {
  const { pathname } = useLocation();

  const isSelected =
    getSegments(pathname)[0] === getSegments(linkData.heading.to)[0];

  return (
    <Container>
      <Heading>
        <LinkHeading selected={isSelected} to={linkData.heading.to}>
          Results
        </LinkHeading>
      </Heading>
      {isSelected && linkData.subnav?.length > 0 && (
        <SubNavContainer>
          <ResultsSubNav
            items={linkData.subnav}
            pathname={getSegments(pathname)[1]}
          />
        </SubNavContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 40px;
  margin: 10px 0 0 20px;
`;

const SubNavContainer = styled.div``;

const LinkHeading = styled(Link)`
  text-decoration: none;
  color: ${(p: { selected: boolean }) =>
    p.selected ? 'white' : colors.greyMenuText};
  font-family: ${(p: { selected: boolean }) =>
    p.selected ? 'Circular Bold' : 'Circular Medium'};
  font-size: 20px;
  line-height: 1.3rem;
  position: relative;
  top: 10px;

  ::before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background: ${colors.pink};
    position: absolute;
    top: 5px;
    left: -25px;
    visibility: ${(p: { selected: boolean }) =>
      p.selected ? 'visible' : 'hidden'};
`;

const Heading = styled.div`
  display: flex;
  height: 40px;
  padding-right: 20px;
`;

export default ResultsNavGroup;
