import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { getSegments } from './ResultsNavGroup';
import colors from '../../../styles/colors';
import AuditLink from '../AuditLink';

type ResultsSubNavProps = {
  items: AuditLink[];
  pathname: string;
};

const ResultsSubNav = ({ items, pathname }: ResultsSubNavProps) => {
  return (
    <Container>
      {items.map((item) => {
        const linkSlug = getSegments(item.to)[1];
        return (
          <SubNavItem key={linkSlug}>
            <SubNavLink selected={linkSlug === pathname} to={item.to}>
              {item.label}
            </SubNavLink>
          </SubNavItem>
        );
      })}
    </Container>
  );
};

const Container = styled.ul`
  padding: 0px;
  margin: 0 0 10px 0;
`;

const SubNavItem = styled.li`
  list-style-type: none;
  margin: 12px 0 0 20px;
`;

const SubNavLink = styled(Link)`
  text-decoration: none;
  line-height: 1.3rem;
  position: relative;

  font-family: ${(p: { selected: boolean }) =>
    p.selected ? 'Circular Medium' : 'Circular Book'};
  text-decoration: none;
  color: ${(p: { selected: boolean }) =>
    p.selected ? 'white' : colors.greyMenuText};
  font-size: 18px;

  }
`;

export default ResultsSubNav;
