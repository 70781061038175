import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import colors from './../styles/colors';
import CSLogo from './../assets/images/cs-logo-red-sky.svg';
import Section from './../components/section/Section.component';
import { Spacer } from './../styles/components/ScreenStyles';

const ClientScreen = (props: any) => {
  const onContactUsPressed = (e: MouseEvent) => {
    window.open(
      `mailto:${process.env.REACT_APP_CONTACT_EMAIL}?subject=Audit%20Report`
    );
  };

  return (
    <Container>
      <Content>
        <Logo>
          <img height="120" src={CSLogo} alt="Circus Street" />
        </Logo>

        <Title>Circus Street Audit</Title>

        <Section icon="mail" heading="Welcome" padding={100}>
          <p>
            You've arrived at teh Circus Street Audit Portal. To view your
            organization's audit, you'll need to follow the link we sent you. If
            you can't find the link, contact us using the button below.
          </p>

          <Spacer height={30} />

          <ButtonContainer>
            <ContactUsButton onClick={onContactUsPressed}>
              Contact us
            </ContactUsButton>
          </ButtonContainer>
        </Section>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  background: ${colors.black};
`;

const Content = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.div`
  margin: 50px 0 13px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: white;
  font-family: 'Circular Bold';
  margin: 0 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ContactUsButton = styled.button`
  font-family: 'Circular Bold';
  font-size: 16px;
  color: ${colors.offBlack};
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  width: 140px;
  background: ${colors.aquaLight};
  cursor: pointer;
  &:active {
    background: ${colors.aqua};
  }
`;

export default ClientScreen;
