import config from '../../../config';
import logoutConfig from './logout';
import { loadingDataRequest } from '../../../utils/LoadingData';
import {
  getOptions,
  getAuthHeader,
  HTTPMethod
} from '../../../utils/httpHelpers';
import { ClaimUIPackage } from '../../../claim-ui/security';

const logout = () => {
  return async (dispatch: Function, getState: Function) => {
    const client = localStorage.getItem('client');
    localStorage.removeItem('client');

    const appUrl = `${config.APP_URL}?client=${client}`;

    const endpoint = `${config.API_URL}/auth/logout`;
    const options = getOptions(
      HTTPMethod.POST,
      getAuthHeader(getState().auditToken.value)
    );
    await loadingDataRequest(dispatch)(endpoint, options, logoutConfig);
    ClaimUIPackage.logout(config.CS_SECURITY_URL, appUrl);
  };
};

export default logout;
