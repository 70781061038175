import React from 'react';
import styled from 'styled-components';

import { upliftBackground } from './../../styles/components/GlobalStyle';

export type UpliftBarData = {
  arrows?: boolean;
  value: number;
  scale?: number;
  top?: number;
  color?: string;
};

const getPercentValue = (value: number, scale: number) => {
  return value ? (value / (scale || 100)) * 100 : 100;
};

const UpliftBar = ({ arrows, scale, value, top, color }: UpliftBarData) => {
  const percentValue = getPercentValue(value, scale || 100);
  return (
    <Container
      arrows={arrows}
      color={color}
      value={percentValue}
      top={top || 0}
    ></Container>
  );
};

type ContainerProps = {
  arrows?: boolean;
  value: number;
  top: number;
  color?: string;
};

const Container = styled.div`
  width: ${(p: ContainerProps) => `${p.value}%`};
  position: absolute;
  height: 8px;
  top: ${(p: ContainerProps) => `${p.top || 0}px`};
  ${upliftBackground};

  border-color: ${(p: ContainerProps) => p.color};
`;

export default UpliftBar;
