import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';

import BehaviourGraph from './BehaviourGraph.component';

export enum AuditType {
  PRE = 'PRE',
  POST = 'POST'
}

type BehaviourChartProps = {
  type?: AuditType;
  title?: string;
  description: string;
  score?: number;
  pre?: number;
  post?: number;
};

const BehaviourChart = ({
  type,
  description,
  title,
  score,
  pre,
  post
}: BehaviourChartProps) => {
  return (
    <Container>
      {title && <Title>{title}</Title>}
      <Description>{description}</Description>
      <BehaviourGraph score={score} pre={pre} post={post} />
    </Container>
  );
};

const Container = styled.div`
  margin: 10px 0;
`;

const Title = styled.h2`
  font-family: 'Circular Medium';
  font-size: 18px;
  margin: 0;
`;

const Description = styled.p`
  font-family: 'Circular Book';
  font-size: 16px;
  margin: 2px 0 20px 0;
  color: ${colors.midGrey};
`;

export default BehaviourChart;
