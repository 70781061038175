import config from '../../../config';
import jobFunctionAliases from './jobFunctionAliases';
import { loadingDataRequest } from '../../../utils/LoadingData';
import {
  getOptions,
  getAuthHeader,
  HTTPMethod
} from '../../../utils/httpHelpers';

export const loadJobFunctionAliases = (token: string, auditId: string) => {
  return async (dispatch: Function, getState: Function) => {
    const endpoint = `${config.API_URL}/jobfunctionalias/${auditId}/items`;
    const options = getOptions(HTTPMethod.GET, getAuthHeader(token));
    await loadingDataRequest(dispatch)(endpoint, options, jobFunctionAliases);
  };
};

export default loadJobFunctionAliases;
