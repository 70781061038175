import React from 'react';

import behaviour from './../../assets/icons/behaviour.svg';
import pie from './../../assets/icons/pie.svg';
import tools from './../../assets/icons/tools.svg';
import brain from './../../assets/icons/brain.svg';
import logout from './../../assets/icons/logout.svg';
import recommendations from './../../assets/icons/recommendations.svg';
import graph from './../../assets/icons/graph.svg';
import mail from './../../assets/icons/mail.svg';
import people_large from './../../assets/icons/people_large.svg';
import people from './../../assets/icons/people.svg';
import bullet from './../../assets/icons/bullet.svg';
import data from './../../assets/icons/data.svg';
import difference from './../../assets/icons/difference.svg';
import distance from './../../assets/icons/distance.svg';
import summary from './../../assets/icons/summary.svg';
import objective from './../../assets/icons/objective.svg';
import methodologies from './../../assets/icons/methodologies.svg';
import insight from './../../assets/icons/insight.svg';
import group from './../../assets/icons/group.svg';
import process from './../../assets/icons/process.svg';
import noresults from './../../assets/icons/no-results.svg';
import minus from './../../assets/icons/minus.svg';
import plus from './../../assets/icons/plus.svg';

const iconMap = new Map();
iconMap.set('behaviour', behaviour);
iconMap.set('pie', pie);
iconMap.set('tools', tools);
iconMap.set('brain', brain);
iconMap.set('logout', logout);
iconMap.set('recommendations', recommendations);
iconMap.set('graph', graph);
iconMap.set('group', group);
iconMap.set('people_large', people_large);
iconMap.set('people', people);
iconMap.set('process', process);
iconMap.set('bullet', bullet);
iconMap.set('data', data);
iconMap.set('difference', difference);
iconMap.set('distance', distance);
iconMap.set('mail', mail);
iconMap.set('summary', summary);
iconMap.set('objective', objective);
iconMap.set('methodologies', methodologies);
iconMap.set('insight', insight);
iconMap.set('noresults', noresults);
iconMap.set('minus', minus);
iconMap.set('plus', plus);

type IconProps = {
  name: string;
  size: number;
};

const Icon = ({ name, size }: IconProps) => {
  return <img src={iconMap.get(name)} alt="" width={size} height={size} />;
};

export default Icon;
