import { getConfig, getKeyReducer } from '../../../utils/LoadingData';

const type = 'SET_BEHAVIOUR_RESULTS';

const behaviour = getConfig({
  type,
  transform: ({ resultsData }: { message: string; resultsData: any[] }) =>
    resultsData,
  reducer: getKeyReducer(type)
});

export default behaviour;
