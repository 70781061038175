import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { shadow } from './../../styles/components/GlobalStyle';
import colors from './../../styles/colors';

type SelectInputProps = {
  options: { value: string; label: string }[];
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
};

const SelectInput = ({ onChange, options, value }: SelectInputProps) => {
  return (
    <Select onChange={onChange} value={value}>
      {options.map(({ value, label }, i) => (
        <option key={i} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );
};

const Select = styled.select`
  ${shadow}
  font-family: 'Circular Medium';
  option {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
  color: ${colors.softBlack};
  font-size: 16px;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #cccccc;
  &:focus {
    outline: 1px solid ${colors.lighterBlueText};
  }
`;

export default SelectInput;
