import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import colors from './../../styles/colors';
import { useSelectedSection } from './../../hooks/useSelectedSection';
import AuditNav from './../auditnav/AuditNav.component';
import MenuHeader from './MenuHeader.component';
import MenuFooter from './MenuFooter.component';

import { AppState } from '../../reducer';
import logout from '../../state/entities/logout/logout.action';

const AuditTypeNames = new Map();
AuditTypeNames.set('new-business', 'New Business Audit');
AuditTypeNames.set('am-pre', 'Pre Audit');
AuditTypeNames.set('amp-post', 'Post Audit');

const SideMenu = (props: any) => {
  const dispatch = useDispatch();

  const auditToken = useSelector((state: AppState) => state.auditToken);

  const reportData = useSelector((state: AppState) => state.report.data);

  const auditTypeName = AuditTypeNames.get(reportData.audit?.auditType);

  const clientName = 'Organization: ' + reportData.client;

  function onLogoutClicked() {
    dispatch(logout());
  }

  const { pathname, hash } = useLocation();

  const { setSelectedSection } = useSelectedSection();

  useEffect(() => {
    setSelectedSection(undefined);
  }, [pathname, hash, setSelectedSection]);

  return (
    <FixedContainer>
      <ContentContainer>
        <MenuHeader
          clientName={clientName}
          auditType={auditTypeName}
        ></MenuHeader>
        <NavContainer>
          <AuditNav />
        </NavContainer>
        <MenuFooter
          onLogout={onLogoutClicked}
          isLogoutDisabled={!auditToken.isLoaded}
        ></MenuFooter>
      </ContentContainer>
    </FixedContainer>
  );
};

const NavContainer = styled.div`
  flex-grow: 1;
`;

const FixedContainer = styled.div`
  position: fixed;
  width: 320px;
  background-color: ${colors.darkBlue};
  height: 100%;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export default SideMenu;
