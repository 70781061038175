import React from 'react';
import styled from 'styled-components';

import Icon from '../icon/Icon.component';
import colors from '../../styles/colors';

const NoResults = () => {
  return (
    <Container>
      <Icon name={'noresults'} size={60} />
      <Message>
        There are not enough results to display here. Please try a different
        filter.
      </Message>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  border: 1px dashed #adadad;
  border-radius: 15px;
  padding: 50px 100px;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.p`
  margin-top: 10px;
  font-family: 'Circular Bold';
  color: ${colors.mediumGrey};
  text-align: center;
  font-size: 18px;
`;

export default NoResults;
