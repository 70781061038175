import config from '../../../config';
import summary from './summary';
import { loadingDataRequest } from '../../../utils/LoadingData';
import {
  getOptions,
  getAuthHeader,
  HTTPMethod
} from '../../../utils/httpHelpers';

export const loadBehaviourSummary = (token: string, auditId: string) => {
  return async (dispatch: Function, getState: Function) => {
    let endpoint = `${config.API_URL}/results/${auditId}/behaviour/summary`;
    const options = getOptions(HTTPMethod.GET, getAuthHeader(token));
    await loadingDataRequest(dispatch)(endpoint, options, summary);
  };
};

export default loadBehaviourSummary;
