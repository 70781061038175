import React from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';
import ScoreBar, { MarkerType } from './../barchart/ScoreBar.component';
import Axis from '../axis/Axis.component';
import { Spacer } from '../../styles/components/ScreenStyles';

type BehaviourGraphProps = {
  score?: number;
  pre?: number;
  post?: number;
};

const BehaviourGraph = ({ score, pre, post }: BehaviourGraphProps) => {
  const isPostAudit = pre && post;
  return (
    <BarsContainer>
      <BackgroundBar />
      <ScoreBar
        thickness={8}
        color={isPostAudit ? colors.blue : colors.sky}
        value={post || score}
        type="solid"
        markerType={MarkerType.NONE}
        label={'45'}
        scale={100}
        top={0}
      />
      {isPostAudit && (
        <ScoreBar
          thickness={8}
          color={colors.sky}
          value={pre}
          type="solid"
          markerType={MarkerType.NONE}
          label={'45'}
          scale={100}
          top={0}
        />
      )}
      <Spacer height={20} />
      <Axis hideNotches markers={['0%', '25%', '50%', '75%', '100%']} />
    </BarsContainer>
  );
};

const BackgroundBar = styled.div`
  height: 8px;
  width: 100%;
  background-color: ${colors.lighterGrey};
`;

// <ColorBar />
//       <Axis hideLine markers={['0%', '25%', '50%', '75%', '100%']} />
//       <ScoreBar
//         thickness={6}
//         color={getScoreColor(score)}
//         value={score}
//         type="none"
//         markerType={MarkerType.NONE}
//         label={score.toString()}
//         scale={100}
//         top={-11}
//       />

// export const getScoreColor = (score: number) => {
//   if (score < 26) {
//     return colors.darkRed;
//   }
//   if (score < 51) {
//     return colors.orange;
//   }
//   if (score < 76) {
//     return colors.yellow;
//   }
//   return colors.aqua;
// };

const BarsContainer = styled.div`
  position: relative;
  height: 60px;
  margin-top: 10px;
`;

// const ColorBar = styled.div`
//   border-radius: 5px;
//   width: 100%;
//   height: 10px;
//   background-image: linear-gradient(
//     to right,
//     ${colors.darkRed} 0%,
//     ${colors.darkRed} 20%,
//     ${colors.orange} 30%,
//     ${colors.orange} 45%,
//     ${colors.yellow} 55%,
//     ${colors.yellow} 70%,
//     ${colors.aqua} 80%,
//     ${colors.aqua} 100%
//   );
// `;

export default BehaviourGraph;
