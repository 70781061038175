import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import ContentBox from './../../styles/components/ContentBox';
import ContentHeading from './../../styles/components/ContentHeading';

type SummaryBoxProps = {
  wrapVertically: boolean;
  heading: string;
};

const SummaryBox: FunctionComponent<SummaryBoxProps> = ({
  wrapVertically,
  heading,
  children
}) => {
  const Element = wrapVertically ? ThreeUpContainer : OuterContainer;
  return (
    <Element>
      <ContentBox>
        <Container>
          <ContentHeading>{heading}</ContentHeading>
          <ContentContainer>{children}</ContentContainer>
        </Container>
      </ContentBox>
    </Element>
  );
};

const ThreeUpContainer = styled.div`
  min-width: 400px;
  margin-bottom: 20px;
  @media (min-width: 1400px) {
    margin-right: 20px;
    &:nth-child(3) {
      margin-right: 0px;
    }
  }
`;

const OuterContainer = styled.div`
  min-width: 400px;
  margin-bottom: 20px;
  @media (min-width: 1400px) {
    margin-right: 20px;
    flex: 0.5;
    :nth-child(even) {
      margin-right: 0;
    }
  }
`;

const Container = styled.div`
  padding: 25px 30px;
`;

const ContentContainer = styled.div`
  margin-top: 20px;
`;

export default SummaryBox;
