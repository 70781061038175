function getAppURL(protocol: string, host: string, appURL: string): string {
  return host.includes(appURL)
    ? `${protocol}//${host}`
    : `${protocol}//${appURL}`;
}

const config = {
  API_URL: `${process.env.REACT_APP_APP_PROTOCOL as string}//${
    process.env.REACT_APP_APP_API_URL as string
  }`,
  APP_URL: getAppURL(
    window.location.protocol,
    window.location.host,
    process.env.REACT_APP_APP_URL as string
  ),
  CS_LOGIN_URL: process.env.REACT_APP_CS_LOGIN_URL as string,
  CS_SECURITY_URL: process.env.REACT_APP_CS_SECURITY_URL as string
};

export default config;
