import { AuditType } from '../components/types/AuditType';

const useShowFilters = (
  filters: string[],
  auditType: AuditType = AuditType.PRE,
  type: string = 'skillset'
) => {
  if (auditType === AuditType.POST && type === 'skillset') {
    return {
      jobFunction: false,
      region: false,
      businessUnit: false
    };
  }
  return {
    jobFunction: !!filters.find((item) => item === 'jobFunction'),
    region: !!filters.find((item) => item === 'region'),
    businessUnit: !!filters.find((item) => item === 'businessUnit')
  };
};

export default useShowFilters;
