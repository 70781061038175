import React from 'react';
import styled from 'styled-components';

import SummaryBox from './../summarybox/SummaryBox.component';
import NetPromoterWheel from './NetPromoterWheel';
import ChartKey from '../chartkey/ChartKey.component';
import colors from '../../styles/colors';

type NetPromoterBoxProps = {
  wrapVertically: boolean;
  detractors: number;
  passives: number;
  promoters: number;
  score: number;
};

const NetPromoterBox = (props: NetPromoterBoxProps) => {
  return (
    <SummaryBox heading="Net Promoter Score" wrapVertically={false}>
      <Container>
        <Wrapper>
          <NetPromoterWheel {...props} />
        </Wrapper>
        <ChartKeyContainer>
          <ChartKey
            items={[
              { color: colors.blue, label: 'detractors' },
              { color: colors.sky, label: 'passives' },
              { color: colors.pink, label: 'promoters' }
            ]}
          />
        </ChartKeyContainer>
      </Container>
    </SummaryBox>
  );
};

const Container = styled.div`
  min-height: 270px;
`;

const Wrapper = styled.div`
  padding: 20px;
  padding-top: 0;
  display: flex;
  justify-content: center;
`;

const ChartKeyContainer = styled.div``;

export default NetPromoterBox;
