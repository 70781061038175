import React from 'react';
import styled from 'styled-components';

import SummaryBox from './../summarybox/SummaryBox.component';
// import IconStat, { IconStatData } from '../stat/IconStat.component';
import Stat, { StatData } from '../stat/Stat';

import BehaviourGraph from './../behaviourchart/BehaviourGraph.component';
import { Spacer } from '../../styles/components/ScreenStyles';
import ChartKey from '../chartkey/ChartKey.component';
import colors from '../../styles/colors';

type BehaviourBoxData = {
  stat: StatData;
  score?: number;
  pre?: number;
  post?: number;
  wrapVertically: boolean;
  numKeyStats: number;
};

const BehaviourBox = ({
  stat,
  score,
  wrapVertically,
  numKeyStats,
  pre,
  post
}: BehaviourBoxData) => {
  const chartKeyItems =
    pre && post
      ? [
          { color: colors.sky, label: 'Average pre score' },
          { color: colors.blue, label: 'Average post score' }
        ]
      : [{ color: colors.sky, label: 'average score' }];

  return (
    <SummaryBox heading="Behavior" wrapVertically={wrapVertically}>
      <Container height={numKeyStats * 40 + 10}>
        {stat && <Stat stat={stat.stat} label={stat.label} direction="row" />}
        <Spacer height={16} />
        <BehaviourGraph score={score} pre={pre} post={post} />
        <Spacer height={10} />
        <ChartKey items={chartKeyItems} />
      </Container>
    </SummaryBox>
  );
};

const Container = styled.div`
  // min-height: 170px; // 170 height for 4 key stats, 210 for 5
  min-height: ${(p: { height: number }) => p.height}px;
`;

export default BehaviourBox;
