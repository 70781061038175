import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

export type KeyData = {
  color: string;
  element?: JSX.Element;
  label: string;
  info?: string;
};

export enum ChartKeyType {
  DEFAULT = 'default',
  DETAILED = 'detailed'
}

type ChartKeyProps = {
  items: KeyData[];
  type?: ChartKeyType;
};

const SimpleChartKey = ({ items }: ChartKeyProps) => {
  return (
    <Container type={ChartKeyType.DEFAULT}>
      {items.map((item, i) => (
        <KeyContainer key={i}>
          {item.element ? (
            <KeyElement>{item.element}</KeyElement>
          ) : (
            <KeyColor color={item.color} />
          )}
          <KeyTitle>
            {item.label}
            {item.info && <KeyInfo>{item.info}</KeyInfo>}
          </KeyTitle>
        </KeyContainer>
      ))}
    </Container>
  );
};

const DetailedChartKey = ({ items, type }: ChartKeyProps) => {
  return (
    <Container type={ChartKeyType.DETAILED}>
      {items.map((item, i) => (
        <KeyContainer key={i}>
          {item.element ? (
            <KeyElement>{item.element}</KeyElement>
          ) : (
            <KeyColor color={item.color} />
          )}
          <KeyTitle bold>
            {item.label}
            {item.info && <KeyInfo>{item.info}</KeyInfo>}
          </KeyTitle>
        </KeyContainer>
      ))}
    </Container>
  );
};

const ChartKey = ({ items, type }: ChartKeyProps) => {
  return type === ChartKeyType.DETAILED ? (
    <DetailedChartKey items={items} />
  ) : (
    <SimpleChartKey items={items} />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: ${(p: { type: ChartKeyType }) =>
    p.type === ChartKeyType.DETAILED ? 'column' : 'row'};
  justify-content: ${(p: { type: ChartKeyType }) =>
    p.type === ChartKeyType.DETAILED ? 'flex-start' : 'space-around'};
`;

// const Title = styled.h3`
//   font-size: 16px;
//   font-family: 'Circular Medium';
//   margin: 10px 0;
// `;

const KeyContainer = styled.dl`
  margin: 5px 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const keyItem = css`
  margin: 0;
  padding: 0;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
`;

const KeyElement = styled.dt`
  ${keyItem}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KeyColor = styled.dt`
  ${keyItem}
  border-radius: 12px;
  border: 3px solid ${(p: { color: string }) => p.color};
  background: white;
`;

const KeyTitle = styled.dd`
  position: relative;
  display: block;
  top: 2px;
  flex-grow: 1;
  margin: 0 0 0 8px;
  padding: 0;
  text-transform: uppercase;
  color: ${colors.softBlack};
  font-family: ${(p: { bold?: boolean }) =>
    p.bold ? 'Circular Medium' : 'Circular Book'};
  font-size: 14px;
`;

const KeyInfo = styled.span`
  margin: 0 0 0 8px;
  padding: 0;
  font-family: 'Circular Book';
  color: ${colors.midGrey};
`;

export default ChartKey;
