import styled from 'styled-components';

import colors from './../../styles/colors';

const BackgroundBar = styled.div`
  position: absolute;
  display: block;
  height: 10px;
  width: 100%;
  background-color: ${colors.lighterGrey};
`;

export default BackgroundBar;
