import React, { useRef, useLayoutEffect } from 'react';
// import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { useScrollSection } from '../hooks/useScrollSection';
import { AppState } from './../reducer';
import Screen from '../components/screen/Screen.component';
import Section from '../components/section/Section.component';
import { useSelectedSection } from '../hooks/useSelectedSection';

const AnalysisScreen = (props: any) => {
  // loaded report data - contains copy but no stats:
  const reportData = useSelector((state: AppState) => state.report.data);

  // const [firstSection, setFirstSection] = useState('skillset');

  // skillset copy (no stats)
  const { skillset, mindset, behaviour, recommendations } =
    reportData.data.analysis;

  const sections = {
    skillset: useRef<HTMLDivElement>(null),
    mindset: useRef<HTMLDivElement>(null),
    behavior: useRef<HTMLDivElement>(null),
    recommendations: useRef<HTMLDivElement>(null)
  };

  const { setSelectedSection } = useSelectedSection();

  useScrollSection(sections);

  useLayoutEffect(() => {
    setTimeout(() => {
      setSelectedSection(
        skillset && skillset.length > 0 ? 'skillset' : 'mindset'
      );
    }, 10);
  }, [setSelectedSection, skillset]);

  return (
    <Screen
      heading="Analysis"
      intro="Here's a summary of the audit and it's findings."
    >
      {skillset && skillset.length > 0 && (
        <Section
          icon="tools"
          heading="Skillset Analysis"
          id="skillset"
          refObject={sections['skillset']}
        >
          {skillset.map((paragraph: string) => (
            <p key={uuidv4()} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </Section>
      )}

      {mindset && mindset.length > 0 && (
        <Section
          icon="brain"
          heading="Mindset Analysis"
          id="mindset"
          refObject={sections['mindset']}
        >
          {mindset.map((paragraph: string) => (
            <p key={uuidv4()} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </Section>
      )}

      {behaviour && behaviour.length > 0 && (
        <Section
          icon="globe_user"
          heading="Behaviour Analysis"
          id="behavior"
          refObject={sections['behavior']}
        >
          {behaviour.map((paragraph: string) => (
            <p key={uuidv4()} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </Section>
      )}

      {recommendations && recommendations.length > 0 && (
        <Section
          icon="report"
          heading="Recommendations"
          id="recommendations"
          refObject={sections['recommendations']}
        >
          {recommendations.map((paragraph: string) => (
            <p key={uuidv4()} dangerouslySetInnerHTML={{ __html: paragraph }} />
          ))}
        </Section>
      )}
    </Screen>
  );
};

export default AnalysisScreen;
