import React from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';
import Bar from './Bar';

type BarChartProps = {
  disagree2: number;
  disagree1: number;
  neutral: number;
  agree1: number;
  agree2: number;
};

const HEIGHT = 188;

const BarChart = ({
  disagree2,
  disagree1,
  neutral,
  agree1,
  agree2
}: BarChartProps) => {
  const biggestValue = Math.max(disagree2, disagree1, neutral, agree1, agree2);
  function calcHeight(value: number) {
    return Math.round((value / biggestValue) * HEIGHT);
  }
  return (
    <Container>
      <Bars>
        <Bar height={calcHeight(disagree2)} />
        <Bar height={calcHeight(disagree1)} />
        <Bar height={calcHeight(neutral)} />
        <Bar height={calcHeight(agree1)} />
        <Bar height={calcHeight(agree2)} />
      </Bars>
      <Labels>
        <Label align={'left'}>completely disagree</Label>
        <Label>neutral</Label>
        <Label align={'right'}>completely agree</Label>
      </Labels>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 10px;
  padding: 0;
`;

const Bars = styled.div`
  display: flex;
  height: ${HEIGHT}px;
  align-items: flex-end;
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const Label = styled.p`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  max-width: 100px;
  line-height: 20px;
  font-family: 'Circular Book';
  color: ${colors.softBlack};
  text-align: ${(p: { align?: string }) => (p.align ? p.align : 'center')};
`;

export default BarChart;
