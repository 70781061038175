import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
  useLayoutEffect
} from 'react';

export enum ScrollDirection {
  UP = 'UP',
  DOWN = 'DOWN'
}

type ScrollContext = {
  scrollPosition: number;
  scrollDirection: ScrollDirection | undefined;
};

const ScrollInfoContext = createContext({
  scrollPosition: 0,
  scrollDirection: undefined
} as ScrollContext);

export const ScrollInfoProvider: FunctionComponent = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const [scrollDirection, setScrollDirection] = useState(
    undefined as ScrollDirection | undefined
  );

  useLayoutEffect(() => {
    const onScroll = () => {
      setScrollPosition((currentScrollPosition: number) => {
        const scrollDir =
          window.scrollY > currentScrollPosition
            ? ScrollDirection.DOWN
            : ScrollDirection.UP;
        setScrollDirection(scrollDir);
        return window.scrollY;
      });
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <ScrollInfoContext.Provider value={{ scrollPosition, scrollDirection }}>
      {children}
    </ScrollInfoContext.Provider>
  );
};

export const useScrollInfo = () => {
  return useContext(ScrollInfoContext);
};
