export enum AuditType {
  PRE = 'PRE',
  POST = 'POST',
  NEW_BUSINESS = 'NEW_BUSINESS'
}

export const auditTypeMap = new Map<string, AuditType>();
auditTypeMap.set('am-pre', AuditType.PRE);
auditTypeMap.set('am-post', AuditType.POST);
auditTypeMap.set('new-business', AuditType.NEW_BUSINESS);
