import React from 'react';
import styled from 'styled-components';

import colors from './../../styles/colors';

type ScoreCircleProps = {
  color?: string;
  score: number;
};

const ScoreCircle = ({ color, score }: ScoreCircleProps) => {
  return (
    <Container color={color}>
      <Score>{score}</Score>
    </Container>
  );
};

const Container = styled.div`
  border: 4px solid;
  border-radius: 20px;
  border-color: ${(p: { color?: string }) => (p.color ? p.color : colors.blue)};
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: cneter;
  justify-content: center;
`;

const Score = styled.span`
  position: relative;
  font-family: 'Circular Bold';
  text-align: center;
  top: 0px;
`;

export default ScoreCircle;
