import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';

import Axis from '../axis/Axis.component';
import ChartKey, { KeyData } from '../chartkey/ChartKey.component';
import ScoreBar, { Bar, BarData } from './ScoreBar.component';

type BarChartProps = {
  markers: string[];
  keyData?: KeyData[];
  barData: BarData[];
};

const BarChart = ({ markers, keyData, barData }: BarChartProps) => {
  return (
    <Container>
      <BarsContainer>
        <Bar color={colors.lightBackgroundGrey} value={100} />
        <ScoreBar {...barData[0]} label={barData[0].label} />
        {barData[1] && <ScoreBar {...barData[1]} label={barData[1].label} />}
      </BarsContainer>

      <Axis markers={markers} />
      {keyData && (
        <ChartKeyContainer>
          <ChartKey items={keyData} />
        </ChartKeyContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const BarsContainer = styled.div`
  position: relative;
  height: 30px;
`;

const ChartKeyContainer = styled.div`
  margin: 10px 0;
`;

export default BarChart;
