import React from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';
import { uplift } from './../../styles/components/GlobalStyle';

const KnowledgeGapStyle = styled.div`
  width: 16px;
  height: 0px;
  border-top: 4px dotted ${colors.red};
`;

const BenchmarkStyle = styled.div`
  height: 18px;
  width: 4px;
  background ${colors.red};
`;

const UpliftStyle = styled.div`
  ${uplift}
  width: 18px;
  height: 8px;
`;

export enum KeyItemId {
  PRE_ASSESSMENT = 'PRE_ASSESSMENT',
  POST_ASSESSMENT = 'POST_ASSESSMENT',
  KNOWLEDGE_GAP = 'KNOWLEDGE_GAP',
  BENCHMARK = 'BENCHMARK',

  PRE_PROGRAMME = 'PRE_PROGRAMME',
  POST_PROGRAMME = 'POST_PROGRAMME',
  UPLIFT = 'uplift',

  PRE_BENCHMARK = 'PRE_BENCHMARK',
  POST_BENCHMARK = 'POST_BENCHMARK',

  ACTUAL = 'ACTUAL',
  TARGET = 'TARGET'
}

const keyItems = {
  [KeyItemId.PRE_ASSESSMENT]: {
    color: colors.sky,
    label: 'Pre Assessment Score',
    info: '(the average of your employees Pre Assessment scores)'
  },
  [KeyItemId.POST_ASSESSMENT]: {
    color: colors.blue,
    label: 'Post Assessment Score',
    info: '(the average of your employees Post Assessment scores)'
  },
  [KeyItemId.KNOWLEDGE_GAP]: {
    color: colors.pink,
    label: 'Knowledge Gap',
    info: '(the difference between your employees Post Assessment scores and the average Post Assessment scores)',
    element: <KnowledgeGapStyle />
  },
  [KeyItemId.BENCHMARK]: {
    color: colors.pink,
    label: 'Post Program Benchmark',
    info: '(how learners tend to score, after Circus Street)',
    element: <BenchmarkStyle />
  },

  [KeyItemId.PRE_PROGRAMME]: {
    color: colors.sky,
    label: 'Pre Program Score',
    info: '(how your employees scored, before Circus Street)'
  },
  [KeyItemId.POST_PROGRAMME]: {
    color: colors.blue,
    label: 'Post Program Score',
    info: '(how your employees scored, after Circus Street)'
  },
  [KeyItemId.UPLIFT]: {
    color: colors.pink,
    label: 'Uplift',
    info: '(how much your employees Mindset has improved)',
    element: <UpliftStyle />
  },

  [KeyItemId.PRE_BENCHMARK]: {
    color: colors.blue,
    label: 'Pre Program Benchmark',
    info: '(average scores, before Circus Street)'
  },
  [KeyItemId.POST_BENCHMARK]: {
    color: colors.pink,
    label: 'Post Program Benchmark',
    info: '(average scores, after Circus Street)'
  },

  [KeyItemId.ACTUAL]: {
    color: colors.sky,
    label: 'Actual Score',
    info: '(how your employees scored, before Circus Street)'
  },
  [KeyItemId.TARGET]: {
    color: colors.pink,
    label: 'Target',
    info: '(target score, after Circus Street)'
  }
};

export default keyItems;
