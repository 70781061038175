import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import NetPromoterBox from '../components/netpromoter/NetPromoterBox';
import TimeUseBox from '../components/timeuse/TimeUseBox';
import { AppState } from './../reducer';
import KeyStatsBox from './../components/keystatsbox/KeyStatsBox.component';
import BehaviourBox from './../components/behaviourbox/BehaviourBox.component';
import SkillsetBox from './../components/skillsetbox/SkillsetBox.component';
import MindsetBox from './../components/mindsetbox/MindsetBox.component';
import Screen from './../components/screen/Screen.component';
import MainHeading from '../styles/components/MainHeading';

const OverviewScreen = (props: any) => {
  const reportData = useSelector((state: AppState) => state.report.data);

  const {
    keyStats,
    behaviourData,
    skillsetData,
    mindsetData,
    netPromoterData,
    timeUseData
  } = reportData.data.overview;

  const showSkillset = !!skillsetData?.stats;

  const numKeyStats = keyStats.length;

  return (
    <Screen
      heading="Overview"
      intro="Here is a summary of the key information from your Pre-Audit Survey."
    >
      <MainHeading>Results Overview</MainHeading>
      <P>Here is a summary of the key information from your audit surveys</P>

      <Container>
        <KeyStatsBox items={keyStats} wrapVertically={!showSkillset} />
        <BehaviourBox
          numKeyStats={numKeyStats}
          stat={behaviourData.stat}
          score={behaviourData.score}
          pre={behaviourData.pre}
          post={behaviourData.post}
          wrapVertically={!showSkillset}
        />
        {showSkillset && (
          <SkillsetBox
            wrapVertically={!showSkillset}
            stats={skillsetData.stats}
            score={skillsetData.score}
            benchmark={skillsetData.benchmark}
          />
        )}
        <MindsetBox
          stats={mindsetData.stats}
          keyLabels={mindsetData.keyLabels}
          wrapVertically={!showSkillset}
        />

        {netPromoterData && (
          <NetPromoterBox wrapVertically={false} {...netPromoterData} />
        )}
        {timeUseData && <TimeUseBox wrapVertically={false} {...timeUseData} />}
      </Container>
    </Screen>
  );
};

const P = styled.p`
  margin: 10px 0 0 0;
`;

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    width: 800px;
  }
  @media (min-width: 1400px) {
    width: 1000px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export default OverviewScreen;
