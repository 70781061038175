import React from 'react';
import styled from 'styled-components';

import colors from './../styles/colors';
import CSLogo from './../assets/images/cs-logo-red-sky.svg';
import Section from './../components/section/Section.component';
import { Spacer } from './../styles/components/ScreenStyles';

const ErrorScreen = (props: any) => {
  return (
    <Container>
      <Content>
        <Logo>
          <img height="120" src={CSLogo} alt="Circus Street" />
        </Logo>

        <Title>Circus Street Audit</Title>

        <Section icon="tools" heading="Page Unavailable" padding={100}>
          <p>{props.children}</p>
          <Spacer height={30} />
        </Section>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  background: ${colors.darkBlue};
`;

const Content = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.div`
  margin: 50px 0 13px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  color: white;
  font-family: 'Circular Medium';
  margin: 0 0;
`;

export default ErrorScreen;
