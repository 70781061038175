import React from 'react';
import styled from 'styled-components';
import IcomoonReact from 'icomoon-react';

import iconSet from './../../assets/fonts/selection.json';
import colors from './../../styles/colors';

type CommentProps = {
  quote: string;
  role: string;
  isLeft: boolean;
};

const Comment = ({ quote, role, isLeft }: CommentProps) => {
  return (
    <Container>
      <IconContainerLeft show={isLeft}>
        <IcomoonReact
          icon="quotation_1"
          iconSet={iconSet}
          size={40}
          color={colors.blue}
        />
      </IconContainerLeft>
      <Quote>{quote}</Quote>
      <Role>{role}</Role>
      <IconContainerRight show={!isLeft}>
        <IcomoonReact
          icon="quotation_2"
          iconSet={iconSet}
          size={40}
          color={colors.blue}
        />
      </IconContainerRight>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  padding: 12px 20px;
`;

const IconContainerLeft = styled.div`
  visibility: ${(p: { show: boolean }) => (p.show ? 'visible' : 'hidden')};
  position: absolute;
  left: 0;
  top: 0;
`;

const IconContainerRight = styled.div`
  visibility: ${(p: { show: boolean }) => (p.show ? 'visible' : 'hidden')};
  position: absolute;
  bottom: 40px;
  right: 0;
`;

const Quote = styled.blockquote`
  border-left: 6px solid ${colors.lightBackgroundGrey};
  padding-left: 14px;
  font-family: 'Circular Book';
  font-size: 16px;
  line-height: 24px;
  margin: 10px 52px;
  color: ${colors.softBlack};
`;

const Role = styled.p`
  margin: 0 50px 0 0;
  text-align: right;
  color: ${colors.midGrey};
`;

export default Comment;
