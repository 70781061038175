import { FilterData } from './FilterData';

export function getEndpoint(baseURL: string, filterData: FilterData) {
  const { auditId, resultsType, jobFunction, region, businessUnit, subject } =
    filterData;
  let endpoint = `${baseURL}/${auditId}/${resultsType}`;
  const params = new URLSearchParams({});
  if (jobFunction) {
    params.append('jobFunction', jobFunction);
  }
  if (region) {
    params.append('region', region);
  }
  if (businessUnit) {
    params.append('businessUnit', businessUnit);
  }
  if (subject) {
    params.append('subject', subject);
  }
  return `${endpoint}?${params.toString()}`;
}
