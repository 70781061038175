import React, {
  useState,
  useEffect,
  FunctionComponent,
  ChangeEvent
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import businessUnits from '../../data/businessUnits.json';
import colors from './../../styles/colors';
import HeaderBar from './HeaderBar';
import { AppState } from './../../reducer';
import ResultsType from '../../state/types/ResultsType';
import loadResultsData from './../../state/entities/results/loadResultsData';
import {
  FiltersContainer,
  SelectContainer,
  SectionHeading,
  Spacer
} from '../../styles/components/ScreenStyles';
import SelectInput from '../../components/selectinput/SelectInput.component';
import BehaviourChart from './../behaviourchart/BehaviourChart.component';
import { useCallback } from 'react';
import NoResults from './../noresults/NoResults.component';
import useAuditId from './../../hooks/useAuditId';
import useShowFilters from '../../hooks/useShowFilters';

type ExpandingItemProps = {
  open?: boolean;
  subject: string;
  score: number;
  jobFunctionOptions: any[];
  regionOptions: any[];
};

const ExpandingItem: FunctionComponent<ExpandingItemProps> = ({
  open,
  subject,
  score,
  jobFunctionOptions,
  regionOptions
}) => {
  const dispatch = useDispatch();

  const data = useSelector((state: AppState) => state.behaviour[subject]);

  const token = useSelector((state: AppState) => state.auditToken.data.token);

  const { auditId } = useAuditId();

  const [isExpanded, setIsExpanded] = useState(open || false);

  const [region, setRegion] = useState<string | undefined>(undefined);

  const [businessUnit, setBusinessUnit] = useState<string | undefined>(
    undefined
  );

  const report = useSelector((state: AppState) => state.report);

  const filters = report.value.filters as string[];

  const showFilters = useShowFilters(filters);

  const [jobFunction, setJobFunction] = useState(
    jobFunctionOptions[0]?.value || 'all'
  );

  const [chartData, setChartData] = useState<
    { score: number; title: string }[] | undefined
  >();

  const onToggleClick = () => {
    const isOpen = !isExpanded;
    setIsExpanded(isOpen);
  };

  const requestChartData = useCallback(
    function (
      jobFunction: string,
      region: string | undefined,
      businessUnit: string | undefined
    ) {
      dispatch(
        loadResultsData(token, {
          auditId,
          resultsType: ResultsType.BEHAVIOUR,
          jobFunction: jobFunction === 'all' ? undefined : jobFunction,
          region: region === 'all' ? undefined : region,
          businessUnit: businessUnit === 'all' ? undefined : businessUnit,
          subject
        })
      );
    },
    [token, auditId, subject, dispatch]
  );

  useEffect(() => {
    requestChartData(jobFunction, region, businessUnit);
  }, [jobFunction, region, businessUnit, requestChartData]);

  useEffect(() => {
    if (data?.value) {
      setChartData(data.value);
    }
  }, [data]);

  useEffect(() => {
    if (isExpanded && !data) {
      requestChartData(jobFunction, region, businessUnit);
    }
  }, [requestChartData, jobFunction, region, businessUnit, isExpanded, data]);

  const onJobChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setJobFunction(e.target.value);
  };

  const onRegionChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(e.target.value);
  };

  const onBusinessUnitChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setBusinessUnit(e.target.value);
  };

  const renderCharts = useCallback(
    (
      chartData:
        | { score: number; title: string; pre?: number; post?: number }[]
        | undefined
    ) => {
      if (data.isError) {
        return <p>Error loading charts</p>;
      }
      if (data.data?.message === 'Not enough results') {
        return <NoResults />;
      }

      if (chartData && chartData.length > 0) {
        return chartData.map((item, i) => {
          const pre = item.pre ? Math.round(item.pre * 100) : undefined;
          const post = item.post ? Math.round(item.post * 100) : undefined;
          const score = item.score ? Math.round(item.score * 100) : undefined;
          return (
            <BehaviourChart
              key={uuidv4()}
              title={'Behavior ' + Number(i + 1)}
              description={item.title}
              score={score}
              pre={pre}
              post={post}
            />
          );
        });
      } else {
        return <NoResults />;
      }
    },
    [data]
  );

  return (
    <Container>
      <HeaderBarContainer onClick={onToggleClick}>
        <HeaderBar isExpanded={isExpanded} title={subject} score={score} />
      </HeaderBarContainer>
      {isExpanded && (
        <ContentContaier>
          <FiltersContainer>
            {showFilters.businessUnit && (
              <SelectContainer>
                <SectionHeading>Business Unit</SectionHeading>
                <SelectInput
                  onChange={onBusinessUnitChanged}
                  options={businessUnits}
                  value={businessUnit}
                />
              </SelectContainer>
            )}
            {showFilters.jobFunction && (
              <SelectContainer>
                <SectionHeading>Job Function</SectionHeading>
                <SelectInput
                  onChange={onJobChanged}
                  options={jobFunctionOptions}
                  value={jobFunction}
                />
              </SelectContainer>
            )}
            {showFilters.region && (
              <SelectContainer>
                <SectionHeading>Region</SectionHeading>
                <SelectInput
                  onChange={onRegionChanged}
                  options={regionOptions}
                  value={region}
                />
              </SelectContainer>
            )}
          </FiltersContainer>
          <Spacer height={20} />

          <Charts>{renderCharts(chartData)}</Charts>
        </ContentContaier>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const HeaderBarContainer = styled.div`
  cursor: pointer;
`;

const Charts = styled.div``;

const ContentContaier = styled.div``;

export default ExpandingItem;
