import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import useAuditId from './hooks/useAuditId';
import { AppState } from './reducer';
import { getToken } from './state/actions/getToken.action';
import saveAuditToken from './state/entities/auditToken/saveAuditToken';
import { LoadingStatus } from './utils/LoadingData';
import loadReportData from './state/entities/report/loadReportData';
import loadJobFunctionAliases from './state/entities/jobFunctionAliases/loadJobFunctionAliases';
import LoadingScreen from './screens/LoadingScreen';
import ErrorScreen from './screens/ErrorScreen';
import ClientScreen from './screens/ClientScreen';
import AuditReportScreen from './screens/AuditReportScreen';
import { getQSParam } from './utils/httpHelpers';
import loadRegionsData from './state/entities/regions/loadRegionData';

function App() {
  const { auditId, setAuditId } = useAuditId();

  const history = useHistory();

  const match = useRouteMatch('/');

  const qsAuditId = getQSParam('auditId');

  const dispatch = useDispatch();

  const auditToken = useSelector((state: AppState) => state.auditToken);

  const report = useSelector((state: AppState) => state.report);

  const regions = useSelector((state: AppState) => state.regions);

  const jobFunctions = useSelector((state: AppState) => state.jobFunctions);

  // set auditId from qs, overriding local storage value:
  useEffect(() => {
    if (qsAuditId !== undefined && !!setAuditId) {
      setAuditId(qsAuditId);
    }
  }, [qsAuditId, setAuditId]);

  useEffect(() => {
    if (!match?.isExact && !auditToken.isLoaded) {
      history.push('/');
    }
  }, [match, auditToken, history]);

  useEffect(() => {
    if (auditId) {
      if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        // DEV: use hardcoded auth token:
        dispatch(
          saveAuditToken({
            token: process.env.REACT_APP_DEV_AUDIT_API_TOKEN as string
          })
        );
      } else {
        // PROD: get token from CS:
        dispatch(getToken());
      }
    }
  }, [dispatch, auditId]);

  useEffect(() => {
    if (
      !!auditId &&
      auditToken.isLoaded &&
      report.status === LoadingStatus.INITIAL
    ) {
      dispatch(loadReportData(auditToken.value, auditId));
      dispatch(loadJobFunctionAliases(auditToken.value, auditId));
      dispatch(loadRegionsData(auditToken.value, auditId));
    }
  }, [auditToken, report, dispatch, auditId]);

  useEffect(() => {
    if (report.isLoaded && jobFunctions.isLoaded && regions.isLoaded) {
      history.push('/executive-summary');
    }
  }, [report, history, jobFunctions, regions]);

  const renderScreen = useCallback(
    (debug: boolean = false) => {
      if (debug) {
        return (
          <ErrorScreen>
            You have made a grave error. A grave error indeed.
          </ErrorScreen>
        );
      }
      if (!auditId) {
        return <ClientScreen></ClientScreen>;
      }
      if (report.isError) {
        return <ErrorScreen>{report.errorMessage}</ErrorScreen>;
      }
      if (auditToken.isError) {
        return <ErrorScreen>{auditToken.errorMessage}</ErrorScreen>;
      }
      if (!auditToken.isLoaded || !report.isLoaded) {
        return <LoadingScreen />;
      }
      return <AuditReportScreen />;
    },
    [auditToken, report, auditId]
  );

  return renderScreen();
}

export default App;
