import React from 'react';
import styled from 'styled-components';

import SummaryBox from './../summarybox/SummaryBox.component';
import colors from '../../styles/colors';
import BarChart from './BarChart';

type TimeUseBoxProps = {
  wrapVertically: boolean;
  disagree2: number;
  disagree1: number;
  neutral: number;
  agree1: number;
  agree2: number;
};

const TimeUseBox = (props: TimeUseBoxProps) => {
  return (
    <SummaryBox heading="Use of Time" wrapVertically={false}>
      <Container>
        <Description>
          &ldquo;This elearning training was a valuable use of my time.&rdquo;
        </Description>
        <BarChart {...props} />
      </Container>
    </SummaryBox>
  );
};

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-family: 'Circular Book';
  font-size: 16px;
  color: ${colors.midGrey};
`;

const Container = styled.div`
  min-height: 270px;
`;

export default TimeUseBox;
