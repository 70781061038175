import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LoadingImage } from './../assets/images/loader.svg';
import colors from './../styles/colors';

const LoadingScreen = (props: any) => {
  return (
    <Container>
      <LoaderContainer>
        <LoadingImage />
      </LoaderContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: ${colors.darkBlue};
`;

const LoaderContainer = styled.div`
  width: 50px;
  height: 50px;
`;

export default LoadingScreen;
