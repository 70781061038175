import React from 'react';
import styled from 'styled-components';

import SummaryBox from '../summarybox/SummaryBox.component';
import ProgressDial, {
  DialData
} from './../progressdial/ProgressDial.component';
import ChartKey from '../chartkey/ChartKey.component';
import colors from '../../styles/colors';

type MindsetBoxProps = {
  stats: DialData[];
  keyLabels: {
    score: string;
    benchmark: string;
  };
  wrapVertically: boolean;
};

const MindsetBox = ({ stats, keyLabels, wrapVertically }: MindsetBoxProps) => {
  return (
    <SummaryBox heading="Mindset" wrapVertically={wrapVertically}>
      <Container wrapVertically={wrapVertically}>
        {stats.map((dialData, i) => (
          <DialContainer key={i}>
            <ProgressDial
              heading={dialData.heading}
              subheading={dialData.subheading}
              value={dialData.value}
              benchmark={dialData.benchmark}
            />
          </DialContainer>
        ))}
      </Container>

      <ChartKeyContainer>
        <ChartKey
          items={[
            { color: colors.sky, label: keyLabels.score },
            { color: colors.pink, label: keyLabels.benchmark }
          ]}
        />
      </ChartKeyContainer>
    </SummaryBox>
  );
};

const ChartKeyContainer = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  min-height: 100px;
  @media (min-width: 1400px) {
    flex-direction: column;
    margin: 0 20px;
    min-height: ${(p: { wrapVertically: boolean }) =>
      p.wrapVertically ? `385px` : `379px`};
  }
`;

const DialContainer = styled.div`
  flex: 0.5;
  margin-bottom: 20px;
`;

export default MindsetBox;
