import config from '../../../config';
import { ClaimUIPackage } from '../../../claim-ui/security';

export default async function getCSAuthToken(): Promise<string | undefined> {
  try {
    // request to security service. If this is unauthorized,
    // a 401 response is returned. This is caught like an error
    // and redirect is made to CS login.
    // If the cookie is valid, this should return with a 200 and
    // token is is set in claim package.
    // if everything is successful, the token is returned as confirmation of auth.
    await ClaimUIPackage.getTokenServer(config.CS_SECURITY_URL);
    const token = ClaimUIPackage.getToken();
    if (!token) {
      throw new Error();
    }
    return token;
  } catch (error) {
    ClaimUIPackage.login(config.CS_LOGIN_URL, config.APP_URL);
  }
}
