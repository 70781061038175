import { useLayoutEffect, MutableRefObject } from 'react';

import { useScrollInfo } from '../hooks/useScrollInfo';
import {
  useSelectedSection,
  getContainingElement,
  getVerticalElement
} from '../hooks/useSelectedSection';

type SectionKeys = {
  [key: string]: MutableRefObject<HTMLDivElement | null>;
};

export const useScrollSection = (sections: SectionKeys) => {
  const { scrollPosition } = useScrollInfo();
  const { setSelectedSection, selectedSection } = useSelectedSection();
  useLayoutEffect(() => {
    const elements = Object.entries(sections).map(([id, ref]) =>
      getVerticalElement(ref.current, id)
    );
    const containingElement = getContainingElement(elements, 100);
    setSelectedSection(containingElement?.id || selectedSection);
  }, [scrollPosition, setSelectedSection, selectedSection, sections]);
};
