import { createContext, useContext, FunctionComponent } from 'react';
import useLocalStorage from 'react-use-localstorage';

type TAuditIdContent = {
  auditId: string;
  setAuditId: React.Dispatch<string>;
};

const AuditIdContext = createContext<TAuditIdContent>({} as TAuditIdContent);

export const AuditIdProvider: FunctionComponent = ({ children }) => {
  const [auditId, setAuditId] = useLocalStorage('auditId', undefined);

  return (
    <AuditIdContext.Provider value={{ auditId, setAuditId }}>
      {children}
    </AuditIdContext.Provider>
  );
};

const useAuditId = () => {
  return useContext(AuditIdContext);
};

export default useAuditId;
