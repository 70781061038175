import React from 'react';
import styled from 'styled-components';

import ChartKey from '../chartkey/ChartKey.component';
import SummaryBox from './../summarybox/SummaryBox.component';
import Stat, { StatData } from '../stat/Stat';
import BarChart from './../barchart/BarChart.component';
import colors from '../../styles/colors';
import { MarkerType } from './../barchart/ScoreBar.component';
import { Spacer } from '../../styles/components/ScreenStyles';

type SkillsetData = {
  stats: StatData[];
  score: {
    value: number;
    keyLabel: string;
  };
  benchmark?: {
    value: number;
    keyLabel: string;
  };
  wrapVertically: boolean;
};

const SkillsetBox = ({
  stats,
  score,
  benchmark,
  wrapVertically
}: SkillsetData) => {
  const keyData = [{ color: colors.sky, label: score.keyLabel }];
  const barData = [
    {
      color: colors.sky,
      value: score.value,
      type: 'solid',
      label: score.value.toString(),
      markerType: MarkerType.NONE
    }
  ];

  if (benchmark) {
    keyData.push({ color: colors.blue, label: benchmark.keyLabel });
    barData.unshift({
      color: colors.blue,
      value: benchmark.value,
      type: 'dotted',
      label: benchmark.value.toString(),
      markerType: MarkerType.NONE
    });
  }

  return (
    <SummaryBox heading="Skillset" wrapVertically={wrapVertically}>
      <Container>
        {stats[0] && (
          <Stat stat={stats[0].stat} label={stats[0].label} direction="row" />
        )}

        {stats[1] && (
          <Stat stat={stats[1].stat} label={stats[1].label} direction="row" />
        )}

        <Spacer height={30} />

        <BarChartContainer>
          <BarChart
            markers={['0%', '25%', '50%', '75%', '100%']}
            barData={barData}
          />
        </BarChartContainer>

        <Spacer height={20} />

        <ChartKeyContainer>
          <ChartKey items={keyData} />
        </ChartKeyContainer>
      </Container>
    </SummaryBox>
  );
};

const Container = styled.div`
  // display: flex;
  // flex-direction: column;
  @media (min-width: 1400px) {
    min-height: 410px;
  }
`;

const ChartKeyContainer = styled.div`
  margin: 0;
  position: relative;
  bottom: 0;
`;

const BarChartContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default SkillsetBox;
