import { getConfig } from '../../../utils/LoadingData';

const report = getConfig({
  transform: (data: any) => {
    return {
      audit: {
        _id: data.audit._id,
        auditType: data.audit.auditType,
        useSkillsetData:
          data.audit.questions.skillset &&
          data.audit.questions.skillset.length > 0
      },
      client: data.client,
      filters: data.filters,
      data: data.data,
      _id: data._id
    };
  }
});

export default report;
