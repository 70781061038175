import React from 'react';
import styled from 'styled-components';

// import IconStat, { IconStatData } from '../stat/IconStat.component';
import Stat, { StatData } from '../stat/Stat';
import SummaryBox from './../summarybox/SummaryBox.component';

const KeyStatsBox = ({
  items,
  wrapVertically
}: {
  items: StatData[];
  wrapVertically: boolean;
}) => {
  return (
    <SummaryBox heading="Key Stats" wrapVertically={wrapVertically}>
      <StatsContainer>
        {items.map(({ stat, label }: StatData, i: number) => (
          <Stat key={i} stat={stat} label={label}></Stat>
        ))}
      </StatsContainer>
    </SummaryBox>
  );
};

const StatsContainer = styled.div`
  margin-top: 20px;
  min-height: 170px;
`;

export default KeyStatsBox;
