import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';

type BulletHeadingProps = {
  bold?: string;
};

const BulletHeading: FunctionComponent<BulletHeadingProps> = ({
  children,
  bold
}) => {
  return (
    <Container>
      <Title>
        {bold && <Bold>{bold} </Bold>}
        <FutherCopy>{children}</FutherCopy>
      </Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 16px 20px;
  position: relative;
`;

const Title = styled.span`
  font-size: 16px;
  margin: 0 0 0 10px;
  padding: 0;

  ::before {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 10px;
    background: ${colors.sky};
    position: absolute;
    left: -20px;
    top: 5px;
  }
`;

const Bold = styled.p`
  font-family: 'Circular Bold';
  margin: 0;
`;

const FutherCopy = styled.p`
  font-family: 'DMSans Regular';
  margin: 0;
`;

export default BulletHeading;
