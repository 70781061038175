import auditToken from './auditToken';
import {
  setLoadingData,
  getLoadingData,
  LoadingStatus
} from '../../../utils/LoadingData';

const saveAuditToken = (authTokenObj: { token: string }) => {
  return async (dispatch: Function, getState: Function) => {
    const successAction = setLoadingData(
      auditToken.type,
      getLoadingData(LoadingStatus.SUCCESS, {
        data: authTokenObj,
        value: auditToken.transform(authTokenObj)
      })
    );
    dispatch(successAction);
    return;
  };
};

export default saveAuditToken;
