import AuditLink from './AuditLink';

export type LinkData = {
  [key: string]: {
    icon: string;
    heading: AuditLink;
    subnav: AuditLink[];
  };
};

const linkData: LinkData = {
  executivesummary: {
    icon: 'methodologies',
    heading: {
      to: '/executive-summary',
      label: 'Executive Summary',
      selected: false
    },
    subnav: [
      {
        to: '#introduction',
        label: 'Introduction',
        selected: false
      },
      {
        to: '#methodology',
        label: 'Methodology',
        selected: false
      },
      {
        to: '#businessObjectives',
        label: 'Business Objectives',
        selected: false
      },
      {
        to: '#learningObjectives',
        label: 'Learning Objectives',
        selected: false
      }
    ]
  },
  results: {
    icon: 'pie',
    heading: {
      to: '/results/overview',
      label: 'Results',
      selected: false
    },
    subnav: [
      {
        to: '/results/overview',
        label: 'Overview',
        selected: false
      },
      {
        to: '/results/mindset',
        label: 'Mindset',
        selected: false
      },
      {
        to: '/results/skillset',
        label: 'Skillset',
        selected: false
      },
      {
        to: '/results/behavior',
        label: 'Behavior',
        selected: false
      }
    ]
  },
  analysis: {
    icon: 'pie',
    heading: {
      to: '/analysis',
      label: 'Analysis',
      selected: false
    },
    subnav: []
  },
  learnerComments: {
    icon: 'pie',
    heading: {
      to: '/comments',
      label: 'Learner Comments',
      selected: false
    },
    subnav: []
  }
};

export default linkData;
