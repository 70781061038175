import React from 'react';
import styled from 'styled-components';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import colors from './../../styles/colors';

export type DialData = {
  heading: string;
  subheading: string;
  value: number;
  benchmark: number;
};

const ProgressDial = ({ heading, subheading, value, benchmark }: DialData) => {
  return (
    <Container>
      <ProgressWheel>
        <ProgressContainer>
          <CircularProgressbar
            value={benchmark}
            minValue={0}
            maxValue={5}
            text={''}
            background={false}
            styles={{
              path: {
                stroke: 'none',
                strokeLinecap: 'round',
                transition: 'stroke-dashoffset 0.5s ease 0s'
              },
              trail: {
                stroke: 'transparent'
              }
            }}
          />
        </ProgressContainer>
        <ProgressContainer>
          <CircularProgressbar
            value={value}
            minValue={0}
            maxValue={5}
            text={value.toFixed(1)}
            background={true}
            styles={{
              path: {
                stroke: colors.sky,
                strokeLinecap: 'round',
                transition: 'stroke-dashoffset 0.5s ease 0s'
              },
              trail: {
                stroke: 'transparent'
              },
              text: {
                fill: colors.softBlack,
                fontFamily: 'Circular Medium',
                fontSize: '28px'
              },
              background: {
                fill: 'white'
              }
            }}
          />
        </ProgressContainer>
        <Benchmark>
          <Value>{benchmark}</Value>
        </Benchmark>
      </ProgressWheel>
      <InfoContainer>
        <Info>
          {/* <Heading>{heading}</Heading> */}
          <SubHeading>{subheading}</SubHeading>
        </Info>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  @media (min-width: 1400px) {
    flex-direction: row;
    align-items: center;
  }
`;

const InfoContainer = styled.div``;

const Info = styled.dl`
  margin: 0 0 0 16px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  align-items: center;
  @media (min-width: 1400px) {
    align-items: flex-start;
  }
`;

// const Heading = styled.dt`
//   margin: 0;
//   padding: 0;
//   font-family: 'Circular Medium';
//   font-size: 16px;
//   @media (min-width: 1400px) {
//     font-size: 19px;
//   }
// `;

const SubHeading = styled.dd`
  margin: 0;
  padding: 0 5px 0 0;
  font-family: 'DMSans Regular';
  font-size: 16px;
  color: ${colors.midGrey};
  text-align: center;
  @media (min-width: 1400px) {
    font-size: 18px;
    text-align: left;
  }
`;

const Benchmark = styled.div`
  position: absolute;
  // top: 9px;
  left: -9px;
  width: 26px;
  height: 26px;
  border-radius: 18px;
  background: white;
  border: 3px solid ${colors.pink};
`;

const Value = styled.div`
  position: relative;
  top: 4px;
  height: 20px;
  font-family: 'Circular Medium';
  font-size: 14px;
  text-align: center;
`;

const ProgressWheel = styled.div`
  position: relative;
  width: 75px;
  height: 75px;
`;

const ProgressContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
`;

export default ProgressDial;
