import React from 'react';
import styled from 'styled-components';

import colors from './../../styles/colors';
import ScoreBar, { MarkerType } from './../barchart/ScoreBar.component';
import BackgroundBar from './../barchart/BackgroundBar';
import UpliftBar from './../barchart/UpliftBar.component';
import { AuditType } from '../types/AuditType';

type SkillsetChartProps = {
  type: AuditType;
  title: string;
  pre: number;
  post?: number;
  postBenchmark: number;
  preBenchmark?: number;
};

const SkillsetChart = ({
  type,
  title,
  pre,
  preBenchmark,
  postBenchmark,
  post
}: SkillsetChartProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      {type === AuditType.PRE ? (
        <PreAuditChart
          score={pre}
          preBenchmark={preBenchmark as number}
          benchmark={postBenchmark}
        />
      ) : (
        <PostAuditChart
          pre={pre}
          post={post || 100}
          benchmark={postBenchmark}
        />
      )}
    </Container>
  );
};

type PreAuditChartProps = {
  score: number;
  benchmark: number;
  preBenchmark: number;
};

const PreAuditChart = ({
  score,
  benchmark,
  preBenchmark
}: PreAuditChartProps) => {
  return (
    <BarsContainer>
      <BackgroundBar />
      <UpliftBar color={colors.pink} value={benchmark} top={1} scale={100} />
      <ScoreBar
        thickness={6}
        color={colors.pink}
        value={benchmark}
        type="none"
        markerType={MarkerType.UP}
        label={benchmark.toString()}
        scale={100}
      />
      <ScoreBar
        thickness={10}
        color={colors.blue}
        value={preBenchmark}
        markerType={MarkerType.UP}
        label={preBenchmark.toString()}
        scale={100}
      />
      <ScoreBar
        thickness={10}
        color={colors.sky}
        value={score}
        markerType={MarkerType.SHORT}
        label={score.toString()}
        scale={100}
      />
    </BarsContainer>
  );
};

type PostAuditChartProps = {
  pre: number;
  post: number;
  benchmark: number;
};

const PostAuditChart = ({ pre, post, benchmark }: PostAuditChartProps) => {
  return (
    <Container>
      <BarsContainer>
        <BackgroundBar />
        <UpliftBar value={benchmark} top={1} scale={100} color={colors.pink} />
        <ScoreBar
          thickness={10}
          color={colors.pink}
          value={benchmark}
          type={'none'}
          markerType={MarkerType.UP}
          label={pre.toString()}
          scale={100}
        />
        <ScoreBar
          thickness={10}
          color={colors.blue}
          value={post}
          markerType={MarkerType.SHORT}
          label={post.toString()}
          scale={100}
        />
        <ScoreBar
          thickness={10}
          color={colors.sky}
          value={pre}
          markerType={MarkerType.SHORT}
          label={pre.toString()}
          scale={100}
        />
        {/* 
        
        */}
      </BarsContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 30px;
`;

const Title = styled.h2`
  font-family: 'Circular Medium';
  font-size: 18px;
  margin: 0;
`;

const BarsContainer = styled.div`
  position: relative;
  height: 70px;
  margin-top: 10px;
  width: 100%;
`;

export default SkillsetChart;
