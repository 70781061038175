import AuditLink from './AuditLink';
import { LinkData } from './linkData.const';

const getUpdatedLink = (heading: AuditLink, pathname: string) => {
  return {
    ...heading,
    selected: heading.to === pathname
  };
};

export const getUpdatedSubnav = (
  subnav: AuditLink[],
  hash: string
): AuditLink[] => {
  return subnav.map((item: AuditLink) => {
    return {
      ...item,
      selected: item.to === hash
    };
  });
};

export const getNavLinkData = (
  pathname: string,
  hash: string,
  linkData: LinkData
): LinkData => {
  const updatedLinkData: LinkData = {};
  Object.entries(linkData).forEach(([key, { icon, heading, subnav }]) => {
    updatedLinkData[key] = {
      icon,
      heading: getUpdatedLink(heading, pathname),
      subnav: getUpdatedSubnav(subnav, hash)
    };
  });
  return updatedLinkData;
};
