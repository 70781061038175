import React from 'react';
import styled from 'styled-components';
import CSLogo from './../../assets/images/cs-logo-red-sky.svg';
import colors from './../../styles/colors';

export type MenuHeaderProps = {
  auditType: string;
  clientName: string;
};

const MenuHeader = ({ auditType, clientName }: MenuHeaderProps) => {
  return (
    <Container>
      <Logo>
        <img height="100" src={CSLogo} alt="Circus Street" />
      </Logo>
      <Info>
        <AuditType>{auditType}</AuditType>
        <ClientName>{clientName}</ClientName>
      </Info>
    </Container>
  );
};

const Container = styled.header`
  border-bottom: 1px solid ${colors.lightGreyBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 10px;
`;

const Logo = styled.div`
  margin: 15px 0 13px 0;
`;

const Info = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: -10px 0 18px 0;
`;

const AuditType = styled.h2`
  font-family: 'Circular Bold';
  font-size: 18px;
  margin: 0;
  padding: 0;
  color: white;
`;

const ClientName = styled.p`
  font-family: 'DMSans Regular';
  font-size: 18px;
  margin: 0 0 0 0;
  padding: 0;
  color: white;
`;

export default MenuHeader;
