import styled from 'styled-components';
import colors from './../colors';

const MainHeading = styled.h1`
  font-family: 'Circular Medium';
  font-size: 32px;
  color: ${colors.black};
  margin: 10px 0 0 0;
`;

export default MainHeading;
