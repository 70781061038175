import React from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import styled from 'styled-components';
import colors from '../../styles/colors';

type NetPromoterBoxProps = {
  detractors: number;
  passives: number;
  promoters: number;
  score: number;
};

function NetPromoterWheel({
  detractors,
  passives,
  promoters,
  score
}: NetPromoterBoxProps) {
  const total = detractors + passives + promoters;
  function percentage(value: number) {
    return Math.round((value / total) * 100);
  }
  return (
    <Container>
      <CircularProgressbarWithChildren
        value={percentage(detractors) + percentage(passives)}
        text={``}
        strokeWidth={12}
        styles={buildStyles({
          strokeLinecap: 'butt',
          pathColor: colors.sky,
          trailColor: colors.pink
        })}
      >
        <CircularProgressbarWithChildren
          value={percentage(detractors)}
          text={``}
          strokeWidth={12}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: colors.blue,
            trailColor: 'transparent'
          })}
        >
          <Value>{score}</Value>
        </CircularProgressbarWithChildren>
      </CircularProgressbarWithChildren>
    </Container>
  );
}

const Value = styled.div`
  font-family: 'Circular Medium';
  font-size: 40px;
`;

const Container = styled.div`
  width: 200px;
  height: 200px;
`;

export default NetPromoterWheel;
