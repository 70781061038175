import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from './../reducer';
import { useScoreCharts } from './../hooks/useScoreCharts';
import Screen from '../components/screen/Screen.component';
import Section from '../components/section/Section.component';
import SelectInput from '../components/selectinput/SelectInput.component';

import SkillsetChart from '../components/skillsetchart/SkillsetChart.component';

import businessUnits from '../data/businessUnits.json';
import Axis from '../components/axis/Axis.component';
import ChartKey, {
  ChartKeyType
} from '../components/chartkey/ChartKey.component';
import keyItemsDictionary, { KeyItemId } from '../components/chartkey/keyItems';
import {
  FiltersContainer,
  SelectContainer,
  Spacer,
  ScoreChartContainer,
  SectionHeading
} from '../styles/components/ScreenStyles';
import { AuditType, auditTypeMap } from '../components/types/AuditType';
import ResultsType from '../state/types/ResultsType';
import NoResults from '../components/noresults/NoResults.component';
import useResultsData from './../hooks/useResultsData';
import useAuditId from './../hooks/useAuditId';
import getJobFunctionOptions, {
  JobFunctionOptions
} from './../hooks/useJobFunctionOptions';
import JobFunction from './../state/entities/jobFunctionAliases/JobFunction';
import useShowFilters from '../hooks/useShowFilters';

const SkillsetScreen = (props: any) => {
  const token = useSelector((state: AppState) => state.auditToken.data.token);

  const skillsetResults = useSelector((state: AppState) => state.skillset);

  const resultsData = useResultsData(skillsetResults);

  const report = useSelector((state: AppState) => state.report);

  const auditType = auditTypeMap.get(report.value.audit.auditType) as AuditType;

  const regions = useSelector((state: AppState) => state.regions.value);

  const filters = report.value.filters as string[];

  const showFilters = useShowFilters(filters, auditType, 'skillset');

  // loaded report data - contains copy but no stats:
  const reportData = report.data;

  // skillset copy (no stats)
  const { skillset } = reportData.data;

  const { auditId } = useAuditId();

  const jobFunctions = useSelector(
    (state: AppState) => state.jobFunctions.data
  ) as JobFunction[];

  const [jobFunctionOptions, setJobFunctionOptions] = useState<
    JobFunctionOptions | undefined
  >();

  useEffect(() => {
    if (jobFunctions) {
      setJobFunctionOptions(getJobFunctionOptions(jobFunctions));
    }
  }, [jobFunctions]);

  const {
    onJobChanged,
    businessUnit,
    onRegionChanged,
    onBusinessUnitChanged,
    chartData,
    jobFunction,
    region
  } = useScoreCharts(
    resultsData || undefined,
    auditId || undefined,
    token || undefined,
    ResultsType.SKILLSET
  );

  const keyItems = [
    keyItemsDictionary[KeyItemId.PRE_PROGRAMME],
    auditType === AuditType.POST
      ? keyItemsDictionary[KeyItemId.POST_PROGRAMME]
      : keyItemsDictionary[KeyItemId.PRE_BENCHMARK],
    keyItemsDictionary[KeyItemId.POST_BENCHMARK]
  ];

  function getPreScore(score: number | undefined, pre: number | undefined) {
    if (score !== undefined) {
      return Math.round(score * 100);
    }
    return pre || 0;
  }

  const renderResults = useCallback(() => {
    if (chartData && chartData.length > 0) {
      return chartData?.map((item: any, i: number) => (
        <ScoreChartContainer key={i}>
          <SkillsetChart
            type={auditType}
            title={item.title}
            pre={getPreScore(item.score, item.pre)}
            post={item.post as number}
            postBenchmark={item.postBenchmark}
            preBenchmark={item.preBenchmark}
          />
        </ScoreChartContainer>
      ));
    } else {
      return <NoResults />;
    }
  }, [chartData, auditType]);

  return (
    <Screen
      heading="Skillset"
      intro="This is an in-depth analysis of your organization's skillset."
    >
      {/* Results Section */}
      <Section icon="tools" heading="Skillset" id="skillset">
        <p>{skillset.results.intro}</p>

        <Spacer height={30} />

        <ChartKey items={keyItems} type={ChartKeyType.DETAILED} />

        {/* drop downs */}

        <FiltersContainer>
          {showFilters.businessUnit && (
            <SelectContainer>
              <SectionHeading>Business Unit</SectionHeading>
              <SelectInput
                onChange={onBusinessUnitChanged}
                options={businessUnits}
                value={businessUnit}
              />
            </SelectContainer>
          )}
          {showFilters.jobFunction && (
            <SelectContainer>
              <SectionHeading>Job Function</SectionHeading>
              <SelectInput
                onChange={onJobChanged}
                options={jobFunctionOptions?.all || []}
                value={jobFunction}
              />
            </SelectContainer>
          )}

          {showFilters.region && (
            <SelectContainer>
              <SectionHeading>Region</SectionHeading>
              <SelectInput
                onChange={onRegionChanged}
                options={regions}
                value={region}
              />
            </SelectContainer>
          )}
        </FiltersContainer>

        <Spacer height={30} />

        {renderResults()}

        <Spacer height={30} />

        <Axis
          hideNotches
          markers={['0%', '20%', '40%', '60%', '80%', '100%']}
        />
      </Section>
    </Screen>
  );
};

export default SkillsetScreen;
