import loadAuditToken from '../entities/auditToken/loadAuditToken';
import getCSAuthToken from './helpers/getCSAuthToken';

export const getToken = () => {
  return async (dispatch: Function, getState?: Function) => {
    try {
      const csAuthToken = (await getCSAuthToken()) as string;
      dispatch(loadAuditToken(csAuthToken));
    } catch (error) {
      // error getting csAuth token...
      // console.log(error.message);
    }
  };
};
