import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from './../reducer';

import businessUnits from '../data/businessUnits.json';
import {
  useScoreCharts,
  getObjectives,
  ChartDataGroup
} from './../hooks/useScoreCharts';
import Screen from './../components/screen/Screen.component';
import Section from './../components/section/Section.component';
import SelectInput from './../components/selectinput/SelectInput.component';
import Axis from './../components/axis/Axis.component';
import ChartKey, {
  ChartKeyType
} from './../components/chartkey/ChartKey.component';
import keyItemsDictionary, { KeyItemId } from '../components/chartkey/keyItems';
import {
  FiltersContainer,
  SelectContainer,
  Spacer,
  ScoreChartContainer,
  SectionHeading,
  SubHeading
} from './../styles/components/ScreenStyles';

import { AuditType, auditTypeMap } from '../components/types/AuditType';
import MindsetChart from '../components/mindsetchart/MindsetChart.component';
import ResultsType from '../state/types/ResultsType';
import NoResults from '../components/noresults/NoResults.component';
import useResultsData from './../hooks/useResultsData';
import useAuditId from './../hooks/useAuditId';
import getJobFunctionOptions, {
  JobFunctionOptions
} from './../hooks/useJobFunctionOptions';
import JobFunction from './../state/entities/jobFunctionAliases/JobFunction';
import useShowFilters from '../hooks/useShowFilters';

const MindsetScreen = (props: any) => {
  const results = useSelector((state: AppState) => state.mindset);

  const regions = useSelector((state: AppState) => state.regions.value);

  const resultsData = useResultsData(results);

  const jobFunctions = useSelector(
    (state: AppState) => state.jobFunctions.data
  ) as JobFunction[];

  const [jobFunctionOptions, setJobFunctionOptions] = useState<
    JobFunctionOptions | undefined
  >();

  useEffect(() => {
    if (jobFunctions) {
      setJobFunctionOptions(getJobFunctionOptions(jobFunctions));
    }
  }, [jobFunctions]);

  const [objectives, setObjectives] = useState<ChartDataGroup[]>([]);

  const token = useSelector((state: AppState) => state.auditToken.data.token);

  // loaded report data - contains copy but no stats:
  const reportData = useSelector((state: AppState) => state.report.data);

  // skillset copy (no stats)
  const { mindset } = reportData.data;

  const { auditId } = useAuditId();

  const report = useSelector((state: AppState) => state.report);

  const auditType = auditTypeMap.get(report.value.audit.auditType) as AuditType;

  const filters = report.value.filters as string[];

  const showFilters = useShowFilters(filters);

  const {
    onJobChanged,
    businessUnit,
    onRegionChanged,
    onBusinessUnitChanged,
    chartData,
    jobFunction,
    region
  } = useScoreCharts(
    resultsData || undefined,
    auditId || undefined,
    token || undefined,
    ResultsType.MINDSET
  );

  const keyItems = [
    keyItemsDictionary[KeyItemId.PRE_PROGRAMME],
    auditType === AuditType.POST
      ? keyItemsDictionary[KeyItemId.POST_PROGRAMME]
      : keyItemsDictionary[KeyItemId.TARGET]
  ];

  useEffect(() => {
    const data = getObjectives(chartData || []);
    setObjectives(data);
  }, [chartData]);

  const renderResults = useCallback(() => {
    if (objectives && objectives.length > 0) {
      return objectives?.map((objective, i) => (
        <div key={uuidv4()}>
          <SectionHeading>{objective.title}</SectionHeading>
          <SubHeading>{objective.description}</SubHeading>

          <Spacer height={20} />

          {objective.items?.map((item: any, i: number) => {
            const pre = item.pre ? item.pre.toFixed(1) : item.score.toFixed(1);
            const post = item.post ? item.post.toFixed(1) : undefined;
            return (
              <ScoreChartContainer key={i}>
                <MindsetChart
                  type={auditType}
                  title={item.title}
                  description={item.description as string}
                  target={(item.target as number) || 5}
                  pre={pre}
                  post={post}
                />
              </ScoreChartContainer>
            );
          })}

          <Spacer height={10} />

          <Axis
            hideNotches
            markers={['0', '1.0', '2.0', '3.0', '4.0', '5.0']}
          />

          <Spacer height={30} />
        </div>
      ));
    } else {
      return <NoResults />;
    }
  }, [objectives, auditType]);

  return (
    <Screen
      heading="Mindset"
      intro="This is an in-depth analysis of your organization’s mindset."
    >
      <Section icon="brain" heading="Mindset" id="mindset">
        <p>{mindset.results.intro}</p>

        <ChartKey items={keyItems} type={ChartKeyType.DETAILED} />

        {/* drop downs */}
        <FiltersContainer>
          {showFilters.businessUnit && (
            <SelectContainer>
              <SectionHeading>Business Unit</SectionHeading>
              <SelectInput
                onChange={onBusinessUnitChanged}
                options={businessUnits}
                value={businessUnit}
              />
            </SelectContainer>
          )}
          {showFilters.jobFunction && (
            <SelectContainer>
              <SectionHeading>Job Function</SectionHeading>
              <SelectInput
                onChange={onJobChanged}
                options={jobFunctionOptions?.all || []}
                value={jobFunction}
              />
            </SelectContainer>
          )}

          {showFilters.region && (
            <SelectContainer>
              <SectionHeading>Region</SectionHeading>
              <SelectInput
                onChange={onRegionChanged}
                options={regions}
                value={region}
              />
            </SelectContainer>
          )}
        </FiltersContainer>

        <Spacer height={50} />

        {renderResults()}
      </Section>
    </Screen>
  );
};

export default MindsetScreen;
