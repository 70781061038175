import React, { useCallback } from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';

type AxisProps = {
  hideLine?: boolean;
  hideNotches?: boolean;
  markers: string[];
  markersOver?: boolean;
};

const Axis = ({ markers, hideLine, hideNotches, markersOver }: AxisProps) => {
  const renderMarkers = useCallback(() => {
    return (
      <Labels>
        {markers.map((marker, i) => (
          <Label key={i}>{marker}</Label>
        ))}
      </Labels>
    );
  }, [markers]);

  return (
    <Container>
      {markersOver && renderMarkers()}

      {!hideLine && (
        <>
          {!hideNotches && (
            <Notches>
              <Notch />
              <Notch />
            </Notches>
          )}
          <Line />
        </>
      )}

      {!markersOver && renderMarkers()}
    </Container>
  );
};

const Container = styled.div``;

const Line = styled.div`
  height: 1px;
  background: ${colors.lightBackgroundGrey};
  width: 100%;
`;

const Notches = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Labels = styled.div`
  margin-top: 2px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  font-size: 14px;
  font-family: 'DMSans Regular';
  color: ${colors.mediumGrey};
`;

const Notch = styled.div`
  width: 1px;
  height: 5px;
  background: ${colors.mediumGrey};
`;

export default Axis;
