import { createGlobalStyle, css } from 'styled-components';
import colors from './../colors';

import CircularBold from './../../assets/fonts/CircularXXWeb-Bold.woff';
import CircularBold2 from './../../assets/fonts/CircularXXWeb-Bold.woff2';

import CircularMedium from './../../assets/fonts/CircularXXWeb-Medium.woff';
import CircularMedium2 from './../../assets/fonts/CircularXXWeb-Medium.woff2';

import CircularBook from './../../assets/fonts/CircularXXWeb-Book.woff';
import CircularBook2 from './../../assets/fonts/CircularXXWeb-Book.woff2';

import DMSansRegular from './../../assets/fonts/dmsans-regular-webfont.woff';
import DMSansRegular2 from './../../assets/fonts/dmsans-regular-webfont.woff2';

export const shadow = css`
  border-radius: 0px;
  box-shadow: 0px 0px 3px 3px rgba(100, 100, 100, 0.05);
`;

export const uplift = css`
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAICAYAAADA+m62AAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACqADAAQAAAABAAAACAAAAABicMmvAAAASklEQVQYGWP4XmB54HuBjQMDAcD4Pd/yP1gNI8NBBgbmBs4JRw5g04NQCJPFoQFTIQ4NTDBxQjQLhgIcViMU4lAAM4iFgYACmEIAPkkge7voqqoAAAAASUVORK5CYII=);
  background-repeat: repeat-x;
`;

export const upliftBackground = css`
  height: 0px;
  border-top: 8px dotted ${colors.lightGrey};
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'DMSans Regular';
    src: local('DMSans Regular'), local('CircularBook'),
    url(${CircularBook2}) format('woff2'),
    url(${CircularBook}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circular Medium';
    src: local('Circular Medium'), local('CircularMedium'),
    url(${CircularMedium2}) format('woff2'),
    url(${CircularMedium}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circular Bold';
    src: local('Circular Bold'), local('CircularBold'),
    url(${CircularBold2}) format('woff2'),
    url(${CircularBold}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Circular Book';
    src: local('Circular Book'), local('CircularBook'),
    url(${CircularBook2}) format('woff2'),
    url(${CircularBook}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'DMSans Regular';
    src: local('DMSans Regular'), local('DMSansRegular'),
    url(${DMSansRegular2}) format('woff2'),
    url(${DMSansRegular}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  body {
    background-color: ${colors.lightGreyBackground};
    font-family: 'DMSans Regular';
    font-size: 16px;
    margin: 0;
    height: 100vh;
  }
  p{
    line-height: 24px;
    color: ${colors.darkGreyText}
  }
  #root{
    display:flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }

  b{
    font-family: 'Circular Medium';
    font-weight: 700;
  }
    
`;
