import React from 'react';
import styled from 'styled-components';

import colors from './../../styles/colors';

export type StatData = {
  stat: string;
  label: string;
  direction?: string;
};

const StatComponent = ({ stat, label, direction = 'row' }: StatData) => {
  return (
    <Container direction={direction}>
      <StatHeading direction={direction}>{stat}</StatHeading>
      <Label direction={direction}>{label}</Label>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: flex-start;
  align-items: center;
  padding-left: ${(p: { direction: string }) =>
    p.direction === 'column' ? '0' : '0'};

  :before {
    content: ' ';
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background: ${colors.sky};
  }
`;

const StatHeading = styled.h3`
  font-family: 'Circular Medium';
  font-size: 28px;
  color: ${colors.softBlack};
  margin: 0;
  margin-left: ${(p: { direction: string }) =>
    p.direction === 'column' ? '0' : '20px'};
  width: ${(p: { direction: string }) =>
    p.direction === 'column' ? 'auto' : '70px'};
`;

const Label = styled.p`
  font-family: 'Circular Book';
  font-size: 18px;
  color: ${colors.midGrey};
  margin: 0;
  position: relative;
  text-align: ${(p: { direction: string }) =>
    p.direction === 'column' ? 'center' : 'left'};
  top: ${(p: { direction: string }) =>
    p.direction === 'column' ? '-5px' : '1px'};
  margin-left: ${(p: { direction: string }) =>
    p.direction === 'column' ? '0' : '20px'};
  line-height: 20px;
  @media (min-width: 1400px) {
    width: ${(p: { direction: string }) =>
      p.direction === 'column' ? '90px' : 'auto'};
  }
`;

export default StatComponent;
