// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../styles/colors';
import { HashLink } from 'react-router-hash-link';

const SubNavLink = styled(HashLink)`
  font-family: ${(p: { selected: boolean }) =>
    p.selected ? 'Circular Medium' : 'Circular Book'};
  text-decoration: none;
  color: ${(p: { selected: boolean }) =>
    p.selected ? 'white' : colors.greyMenuText};
  font-size: 18px;
`;

export default SubNavLink;
