import { useState, useEffect } from 'react';
import { LoadingData } from '../utils/LoadingData';

const useResultsData = (results: LoadingData) => {
  const [resultsData, setResultsData] = useState();

  useEffect(() => {
    const resultsData = results?.data?.resultsData;
    if (resultsData) {
      setResultsData(resultsData);
    } else if (results?.data?.message === 'Not enough results') {
      setResultsData([] as any);
    }
  }, [results]);

  return resultsData;
};

export default useResultsData;
