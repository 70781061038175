import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NavLinkGroup from './NavLinkGroup.component';
import ResultsNavGroup from './results-nav/ResultsNavGroup';
import linkData from './linkData.const';
import { getNavLinkData } from './getLinkNavData';
import { AppState } from '../../reducer';

function getSubNavItem(name: string) {
  const lowercase = name.toLowerCase();
  return {
    to: `#${lowercase}`,
    label: lowercase.charAt(0).toUpperCase() + lowercase.slice(1),
    selected: false
  };
}

function getSubnav(sections: (string | null)[]) {
  return sections
    .filter((item) => item !== null)
    .map((section) => getSubNavItem(section as string));
}

function sectionExists(section: any[] | undefined) {
  return section && section.length > 0 ? true : false;
}

const AuditNav = (props: any) => {
  const location = useLocation();

  const [navLinkData, setNavLinkData] = useState(linkData);

  useEffect(() => {
    setNavLinkData(() =>
      getNavLinkData(location.pathname, location.hash, linkData)
    );
  }, [location]);

  const reportData = useSelector((state: AppState) => state.report.data);

  const { analysis, comments } = reportData.data;

  const commentsExist = sectionExists(comments);

  const analysisLinkData = JSON.parse(JSON.stringify(navLinkData.analysis));

  analysisLinkData.subnav = getSubnav([
    sectionExists(analysis.skillset) ? 'skillset' : null,
    sectionExists(analysis.mindset) ? 'mindset' : null,
    sectionExists(analysis.behaviour) ? 'behavior' : null,
    sectionExists(analysis.recommendations) ? 'recommendations' : null
  ]);

  return (
    <Container>
      <AuditNavItem>
        <NavLinkGroup linkData={navLinkData.executivesummary} />
      </AuditNavItem>
      <AuditNavItem>
        <ResultsNavGroup linkData={navLinkData.results} />
      </AuditNavItem>
      <AuditNavItem>
        <NavLinkGroup linkData={analysisLinkData} />
      </AuditNavItem>
      {commentsExist && (
        <AuditNavItem>
          <NavLinkGroup linkData={navLinkData.learnerComments} />
        </AuditNavItem>
      )}
    </Container>
  );
};

const Container = styled.ul`
  margin: 20px 0 0 0;
  padding: 0 0 25px 0;
`;

const AuditNavItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export default AuditNav;
