import React from 'react';
import styled from 'styled-components';

export type BarData = {
  color: string;
  scale?: number;
  type?: string;
  value?: number;
  thickness?: number;
  markerType?: MarkerType;
  label?: string;
  top?: number;
};

const ScoreBar = ({
  color,
  type = 'solid',
  thickness = 6,
  value,
  markerType,
  label,
  top,
  scale
}: BarData) => {
  const markerData = markerMap.get(markerType);
  if (!markerData.labelTop) {
    markerData.labelTop = markerData.height + markerData.top;
  }
  let mdLabelTop = markerData.labelTop;
  let mdTop = markerData.top;
  if (type === 'none') {
    mdLabelTop = markerData.labelTop + 10;
    mdTop = markerData.top + 10;
  }
  let percentValue = value ? (value / (scale || 100)) * 100 : 100;
  if (value === 0) {
    percentValue = 0;
  }
  return (
    <Bar {...{ color, type, thickness, top }} value={percentValue}>
      {markerType && (
        <Marker color={color} height={markerData.height} top={mdTop}></Marker>
      )}
      {label && (
        <Label color={color} top={mdLabelTop}>
          <Value>{value}</Value>
        </Label>
      )}
    </Bar>
  );
};

export const Bar = styled.div`
  width: ${(p: BarData) => `${p.value}%`};
  border-top: ${(p: BarData) =>
    `${p.thickness || 6}px ${p.type || 'solid'} ${p.color}`};
  position: absolute;
  height: 0px;
  top: ${(p: BarData) => `${p.top || 0}px`};
`;

export enum MarkerType {
  LONG = 'long',
  MEDIUM = 'medium',
  SHORT = 'short',
  NONE = 'none',
  RAISED = 'raised',
  LONG_RAISED = 'long_raised',
  UP = 'up'
}

const markerMap = new Map();
markerMap.set(MarkerType.UP, {
  height: 6,
  top: -16,
  labelTop: -50
});
markerMap.set(MarkerType.RAISED, {
  height: 22,
  top: -16
});
markerMap.set(MarkerType.LONG, {
  height: 26,
  top: -2
});
markerMap.set(MarkerType.SHORT, {
  height: 12,
  top: -2
});
markerMap.set(MarkerType.MEDIUM, {
  height: 22,
  top: -2
});
markerMap.set(MarkerType.LONG_RAISED, {
  height: 32,
  top: -26
});
markerMap.set(MarkerType.NONE, {
  height: 0,
  top: -22
});

type MarkerProps = {
  height: number;
  top: number;
  color: string;
};

// the marker that joins the circle to the line
const Marker = styled.div`
  width: 4px;
  height: ${(p: MarkerProps) => p.height}px;
  background: ${(p: MarkerProps) => p.color};
  position: absolute;
  top: ${(p: MarkerProps) => p.top}px;
  right: 0;
  z-index: 10;
`;

// the circle containing the number
const Label = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 18px;
  border: 4px solid ${(p: { color: string; top: number }) => p.color};
  background: white;
  position: absolute;
  top: ${(p: { color: string; top: number }) => p.top}px;
  right: -15px;
`;

// the number in the circle
const Value = styled.div`
  position: relative;
  top: 4px;
  width: 30px;
  left: -1px;
  font-family: 'Circular Medium';
  text-align: center;
  font-size: 16px;
`;

export default ScoreBar;
