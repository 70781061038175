import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type ScreenProps = {
  heading: string;
  intro: string;
};

const Screen: FunctionComponent<ScreenProps> = ({
  heading,
  intro,
  children
}) => {
  return (
    <Container>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

const Container = styled.div``;

const ContentContainer = styled.div`
  margin-top: 33px;
`;

export default Screen;
