import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import ExpandingItem from '../expandingitem/ExpandingItem';

type BehaviourItemsDisplayProps = {
  data: any[] | undefined;
  jobFunctionOptions: any[];
  regionOptions: any[];
};

const BehaviourItemsDisplay = ({
  data,
  jobFunctionOptions,
  regionOptions
}: BehaviourItemsDisplayProps) => {
  return (
    <Container>
      {data &&
        data.map((item) => (
          <ExpandingItem
            key={uuidv4()}
            subject={item.subject}
            score={Math.round(item.score * 100)}
            jobFunctionOptions={jobFunctionOptions}
            regionOptions={regionOptions}
          />
        ))}
    </Container>
  );
};

const Container = styled.div``;

export default React.memo(BehaviourItemsDisplay);
