import React, { FunctionComponent, RefObject } from 'react';
import styled from 'styled-components';
import IcomoonReact from 'icomoon-react';

import iconSet from './../../assets/fonts/selection.json';
import ContentHeading from '../../styles/components/ContentHeading';
import ContentBox from '../../styles/components/ContentBox';
// import Icon from '../icon/Icon.component';
import { Spacer } from '../../styles/components/ScreenStyles';
import colors from '../../styles/colors';

type SectionProps = {
  icon: string;
  heading: string;
  id?: string;
  refObject?: RefObject<HTMLDivElement>;
  padding?: number;
};

const Section: FunctionComponent<SectionProps> = ({
  icon,
  heading,
  children,
  id,
  refObject,
  padding
}) => {
  return (
    <Container id={id} ref={refObject}>
      <ContentBox>
        <Wrapper padding={padding}>
          <ContentContainer>
            <IcomoonReact
              iconSet={iconSet}
              color={colors.blue}
              size={40}
              icon={icon}
            />
            <Spacer height={10} />
            <ContentHeading>{heading}</ContentHeading>
            {children}
          </ContentContainer>
        </Wrapper>
      </ContentBox>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  padding-left: ${(p: { padding?: number }) =>
    p.padding ? `${p.padding}px` : '50px'};
  padding-right: ${(p: { padding?: number }) =>
    p.padding ? `${p.padding}px` : '50px'};
  padding-bottom: 30px;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 650px;
`;

export default Section;
