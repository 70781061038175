import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';

export type TabData = {
  id: string;
  label: string;
};

type TabsProps = {
  onChange: (tabData: TabData) => void;
  tabData: TabData[];
  selectedTabId: string;
};

const Tabs = ({ tabData, selectedTabId, onChange }: TabsProps) => {
  const tabMap = useMemo(() => {
    const tMap = new Map();
    tabData.forEach((item) => {
      tMap.set(item.id, item);
    });
    return tMap;
  }, [tabData]);

  const [selectedTab, setSelectedTab] = useState<TabData>(
    tabMap.get(selectedTabId)
  );

  const onTabClicked = (tabData: TabData) => {
    setSelectedTab(tabData);
    onChange(tabData);
  };

  return (
    <Container>
      {tabData.map((item) => {
        const selected = item.id === selectedTab.id;
        return (
          <TabButton
            className={selected ? 'selected' : ''}
            key={item.id}
            onClick={() => {
              onTabClicked(item);
            }}
          >
            {item.label}
          </TabButton>
        );
      })}
    </Container>
  );
};

const Container = styled.div``;

const TabButton = styled.button`
  font-size: 17px;
  color: ${colors.blue};
  font-family: 'DMSans Regular';
  background: white;
  border: 1px solid ${colors.blue};
  margin: 0;
  padding: 0;
  width: 180px;
  height: 40px;
  cursor: pointer;
  // &:first-child {
  //   border-top-left-radius: 20px;
  //   border-bottom-left-radius: 20px;
  // }
  // &:last-child {
  //   border-top-right-radius: 20px;
  //   border-bottom-right-radius: 20px;
  // }
  -webkit-transition: color 0.15s ease-out, background 0.3s ease-out;
  -moz-transition: color 0.15s ease-out, background 0.3s ease-out;
  -o-transition: color 0.15s ease-out, background 0.3s ease-out;
  transition: color 0.15s ease-out, background 0.3s ease-out;
  &.selected {
    color: white;
    background: ${colors.blue};
  }
`;

export default Tabs;
