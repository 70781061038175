import { useState, useEffect, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import Region from './../state/types/Region';
import ResultsType from './../state/types/ResultsType';

import loadResultsData from '../state/entities/results/loadResultsData';

type ChartDataItem = {
  objective?: { title: string; description: string };
  subject?: string;
  title: string;
  preBenchmark?: number;
  postBenchmark?: number;
  target?: number;
  score: number;
  description?: string;
};

export const useScoreCharts = (
  inputData: any[] | undefined,
  auditId: string | undefined,
  token: string | undefined,
  type: ResultsType,
  subject: string | undefined = undefined
) => {
  const dispatch = useDispatch();

  const [region, setRegion] = useState('all');

  const [jobFunction, setJobFunction] = useState('all');

  const [businessUnit, setBusinessUnit] = useState('all');

  const [chartData, setChartData] = useState<ChartDataItem[]>();

  const onJobChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setJobFunction(e.target.value);
  };

  const onRegionChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setRegion(e.target.value);
  };

  const onBusinessUnitChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setBusinessUnit(e.target.value);
  };

  useEffect(() => {
    if (inputData) {
      const newData = inputData.map((item) => {
        return {
          ...(item.objective && {
            objective: {
              title: item.objective?.title,
              description: item.objective?.description
            }
          }),
          subject: item.subject,
          title: item.lesson || item.title,
          target: item.target,
          description: item.title || item.question,
          preBenchmark: item.benchmarks?.pre || undefined,
          postBenchmark: item.benchmarks?.post || undefined,
          pre: item.scores?.pre || item.pre || undefined,
          post: item.scores?.post || item.post || undefined,
          score: item.score
        };
      });
      setChartData(newData);
    }
  }, [region, jobFunction, businessUnit, inputData]);

  // load skillset results data:
  useEffect(() => {
    if (!auditId || !token) {
      return;
    }
    const rg = region === 'all' ? undefined : (region as Region);
    const jfCode = jobFunction === 'all' ? undefined : jobFunction;
    const buCode = businessUnit === 'all' ? undefined : businessUnit;
    dispatch(
      loadResultsData(token, {
        auditId,
        resultsType: type,
        jobFunction: jfCode,
        region: rg,
        businessUnit: buCode,
        subject
      })
    );
  }, [
    token,
    dispatch,
    auditId,
    jobFunction,
    region,
    businessUnit,
    type,
    subject
  ]);

  return {
    chartData,
    onJobChanged,
    onRegionChanged,
    businessUnit,
    onBusinessUnitChanged,
    jobFunction,
    region,
    setJobFunction
  };
};

export type ChartDataGroup = {
  title: string;
  description?: string;
  items: ChartDataItem[];
};

export function getObjectives(chartData: ChartDataItem[]): ChartDataGroup[] {
  const objectivesMap = new Map();
  chartData.forEach((item) => {
    if (!objectivesMap.get(item.objective?.title)) {
      objectivesMap.set(item.objective?.title, {
        title: item.objective?.title,
        description: item.objective?.description,
        items: []
      });
    }
    const objective = objectivesMap.get(
      item.objective?.title
    ) as ChartDataGroup;
    objective.items.push(item);
  });

  return Array.from(objectivesMap.values());
}

export function getBehaviourGroups(
  chartData: ChartDataItem[]
): ChartDataGroup[] {
  const behaviourGroupMap = new Map();
  chartData.forEach((item) => {
    if (!behaviourGroupMap.get(item.subject)) {
      behaviourGroupMap.set(item.subject, {
        title: item.subject,
        items: []
      });
    }
    const bGroup = behaviourGroupMap.get(item.subject) as ChartDataGroup;
    bGroup.items.push(item);
  });

  return Array.from(behaviourGroupMap.values());
}
