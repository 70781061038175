export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  OPTIONS = 'OPTIONS'
}

export function getOptions(method: HTTPMethod, headers?: any, body?: any) {
  return {
    method,
    headers,
    body
  };
}

export function getAuthHeader(token: string) {
  return {
    Authorization: `Bearer ${token}`
  };
}

export const getQSParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param) || undefined;
};
