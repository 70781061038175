import React, {
  createContext,
  FunctionComponent,
  useState,
  useContext,
  MutableRefObject
} from 'react';

type TSelectedSectionContext = {
  selectedSection: string | undefined;
  setSelectedSection: (section: string | undefined) => void;
};

const SelectedSectionContext = createContext({} as TSelectedSectionContext);

export const SelectedSectionProvider: FunctionComponent = ({ children }) => {
  const [selectedSection, setSelectedSection] =
    useState<string | undefined>(undefined);

  return (
    <SelectedSectionContext.Provider
      value={{ selectedSection, setSelectedSection }}
    >
      {children}
    </SelectedSectionContext.Provider>
  );
};

export const useSelectedSection = () => {
  return useContext(SelectedSectionContext);
};

export function getScrollPosition(
  element: MutableRefObject<HTMLDivElement | null>
) {
  const target = element ? element.current : document.body;
  return (
    target?.getBoundingClientRect() ||
    ({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    } as DOMRect)
  );
}

export type VerticalElement = {
  top: number;
  bottom: number;
  id: string;
};

export const getVerticalElement = (
  element: HTMLDivElement | null,
  id: string
) => {
  const bounds = element?.getBoundingClientRect();
  return {
    id,
    top: bounds?.top || 0,
    bottom: bounds?.bottom || 0
  };
};

const isContained = ({ top, bottom }: VerticalElement, y: number) => {
  return top <= y && bottom >= y;
};

export const getContainingElement = (items: VerticalElement[], y: number) => {
  return items.find((item) => isContained(item, y));
};

export const getSelectedScreen = (
  resultsPos: VerticalElement,
  analysisPos: VerticalElement,
  recommendationsPos: VerticalElement,
  y: number
) => {
  if (isContained(resultsPos, y)) {
    return 'results';
  }
  if (isContained(analysisPos, y)) {
    return 'analytics';
  }
  if (isContained(recommendationsPos, y)) {
    return 'recommendations';
  }
  return undefined;
};
