const colors = {
  lightGreyBackground: '#E6E6E6',
  mediumGrey: '#ADADAD',
  yellow: '#FECF28',
  lightYellow: 'rgb(254,207,40, 0.1)',
  red: '#FF6600',
  darkRed: '#CC3300',
  darkGreyText: '#484848',
  navy: '#264353',
  navyLighter: '#48687b',

  black: '#000000',
  blackLighter: '#333333',
  blue: '#2969E8',
  darkBlue: '#1B4598',
  blueLighter: '#6A88C3',
  greyMenuText: '#BBBBBB',
  sky: '#94BDFA',
  pink: '#FABDC9',
  midGrey: '#919191',

  // mobile app colors:
  darkNavy: '#032D4A',
  navyInputBackground: '#2A4D66',
  orange: '#FF6600',
  aqua: '#00CCCC',
  aquaLight: '#99EAEA',
  lightRed: '#F3A6A6',
  offBlack: '#262626',
  softBlack: '#333132',
  darkGrey: '#58595B',
  lightGrey: '#E6E7E8',
  lighterGrey: '#F6F7F8',
  cobalt: '#00A6A6',
  lightBackgroundGrey: '#E8E8E8',
  lightGreyBorder: '#DADADA',
  inProgress: '#F7B500',
  doneGreen: '#6DD78D',

  // sliding menu drawer:
  darkBlueBackground: '#0F2430',
  blueMenuItem: '#1E3B49',
  blueMenuHeading: '#315466',
  lightBlueText: '#AABEC9',
  lighterBlueText: '#CEDBE3',
  selectedGreen: '#35AC59'
};

export default colors;
