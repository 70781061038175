import styled from 'styled-components';
import colors from '../colors';

export const Spacer = styled.hr`
  border: none;
  margin-top: ${(p: { height: number }) => p.height}px;
`;

export const FiltersContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const SelectContainer = styled.div`
  flex: 0.5;
  &:first-child {
    padding-right: 30px;
  }
`;

export const ScoreChartContainer = styled.div`
  margin-top: 0;
`;

export const SectionHeading = styled.h3`
  font-family: 'Circular Medium';
  font-weight: 400;
  font-size: 18px;
  margin: 5px 0 15px 0;
  color: ${colors.softBlack};
`;

export const SubHeading = styled.h3`
  font-family: 'Circular Medium';
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  margin: -5px 0 15px 0;
  color: ${colors.softBlack};
`;
