import JobFunction from './../state/entities/jobFunctionAliases/JobFunction';

type Option = {
  value: string;
  label: string;
};

export type JobFunctionOptions = {
  all: Option[];
  commercial: Option[];
  nonCommercial: Option[];
};

const defaultOptions: JobFunctionOptions = {
  all: [
    {
      value: 'all',
      label: 'All Job Functions'
    }
  ],
  commercial: [
    {
      value: 'commercial',
      label: 'All Commercial Job Functions'
    }
  ],
  nonCommercial: [
    {
      value: 'non-commercial',
      label: 'All Non-Commercial Job Functions'
    }
  ]
};

export default function getJobFunctionOptions(jobFunctions: JobFunction[]) {
  const jobFunctionOptions = { ...defaultOptions };
  const optionData = getOptionData(jobFunctions);
  const commercialOptions = optionData.filter((option) =>
    isCommercial(option.value)
  );
  const nonCommercialOptions = optionData.filter(
    (option) => !isCommercial(option.value)
  );
  jobFunctionOptions.all = [...jobFunctionOptions.all, ...optionData];
  jobFunctionOptions.commercial = [
    ...jobFunctionOptions.commercial,
    ...commercialOptions
  ];
  jobFunctionOptions.nonCommercial = [
    ...jobFunctionOptions.nonCommercial,
    ...nonCommercialOptions
  ];
  return jobFunctionOptions;
}

function getOptionData(jobFunctions: JobFunction[]) {
  return jobFunctions
    .map((jf) => ({
      value: jf.code,
      label: jf.description
    }))
    .sort((optionA, optionB) => {
      return optionA.value <= optionB.value ? -1 : 1;
    });
}

function isCommercial(jobFunctionCode: string) {
  return jobFunctionCode === 'sales' || jobFunctionCode === 'marketing';
}
