import styled from 'styled-components';
import colors from './../colors';

const ContentHeading = styled.h1`
  font-family: 'Circular Medium';
  font-size: 28px;
  color: ${colors.softBlack};
  margin: 0 0 0 0;
`;

export default ContentHeading;
