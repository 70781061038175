import React from 'react';
import styled from 'styled-components';

import colors from '../../styles/colors';

type BarProps = {
  height: number;
};

const Bar = ({ height }: BarProps) => {
  return (
    <Container>
      <Block height={height} />
      <Line />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  align-items: center;
  position: relative;
`;

const Block = styled.div`
  margin: 0 5px;
  height: 30px;
  background: ${colors.sky};
  height: ${(p: { height: number }) => p.height}px;
`;

const Line = styled.div`
  width: auto;
  height: 1px;
  background: ${colors.lightBackgroundGrey};
`;

export default Bar;
