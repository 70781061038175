import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

import colors from './../../styles/colors';

type MenuFooterProps = {
  onLogout: MouseEventHandler<HTMLButtonElement>;
  isLogoutDisabled: boolean;
};

const MenuFooter = ({ onLogout, isLogoutDisabled }: MenuFooterProps) => {
  return (
    <Container>
      <Button onClick={onLogout} disabled={isLogoutDisabled}>
        <Label>Log out</Label>
      </Button>
    </Container>
  );
};

const Button = styled.button`
  background: none;
  border: 1px solid white;
  padding: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 50px;
  width: 210px;
`;

const Label = styled.span`
  font-size: 16px;
  color: white;
  font-family: 'Circular Medium';
`;

const Container = styled.footer`
  align-self: flex-end;
  border-top: 1px solid ${colors.lightGreyBackground};
  padding: 40px 0;
  width: 100%;
`;

export default MenuFooter;
