import { getConfig } from '../../../utils/LoadingData';

const regions = getConfig({
  transform: (data: any) => {
    const regionOptions = [{ value: 'all', label: 'All Regions' }];
    data.forEach(({ name }: { name: string }) => {
      regionOptions.push({
        value: name,
        label: name
      });
    });
    return regionOptions;
  }
});

export default regions;
